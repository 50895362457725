import type { SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import { isSportFamilyEquals } from 'web/src/modules/sportline/utils';

type FindGuard = (element?: SportsTreeSportElement) => boolean;

/**
 * Method used in .find()
 */

export function sportElementEqualsTo(
  sportFamily?: string | null,
  extraCondition?: FindGuard,
): FindGuard {
  if (!sportFamily) {
    return (element) => (extraCondition ? extraCondition(element) : false);
  }

  return (element) => isSportFamilyEquals(element?.sport, sportFamily)
    || (extraCondition ? extraCondition(element) : false);
}
