import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { getSportradarLiveMatchTrackerConfiguration } from '@leon-hub/api-sdk';
import { logger } from '@leon-hub/logging';

import { useGraphqlClient } from '@core/app-rest-client';

import type {
  SportradarLiveMatchTrackerWidgetConfig,
  SportradarLiveMatchTrackerWidgetFrameConfig,
} from '../types';

interface UseSportradarLiveMatchTrackerConfigProps {
  iframeUrl: Ref<Optional<string>>;
}

interface UseSportradarLiveMatchTrackerConfigComposable {
  config: Ref<Maybe<SportradarLiveMatchTrackerWidgetConfig>>;
  configFinal: Ref<Maybe<SportradarLiveMatchTrackerWidgetFrameConfig>>;
  fetchConfig(): Promise<void>;
}

export function useSportradarLiveMatchTrackerConfigStoreComposable(
  props: UseSportradarLiveMatchTrackerConfigProps,
): UseSportradarLiveMatchTrackerConfigComposable {
  const { iframeUrl } = props;

  const api = useGraphqlClient();

  const config = ref<Maybe<SportradarLiveMatchTrackerWidgetConfig>>(null);

  const configFinal = computed<SportradarLiveMatchTrackerWidgetFrameConfig | null>(() => (
    config.value
      ? {
          ...config.value,
          iframeUrl: iframeUrl.value,
        }
      : null
  ));

  async function fetchConfig(): Promise<void> {
    try {
      config.value = await getSportradarLiveMatchTrackerConfiguration(
        api,
        (node) => node.queries.configContent.getSportRadarWidgetConfiguration,
        { options: {} },
      );
    } catch (error) {
      logger.error('An error occurred while updating Sportradar Live Match Tracker data', error);
    }
  }

  return {
    config,
    configFinal,
    fetchConfig,
  };
}
