<script lang="ts" setup>
import { onDeactivated } from 'vue';

import type {
  FilterTransitionElementRef,
  FilterTransitionId,
  FilterTransitionProps,
} from './types';
import useFilterTransition from './composables/useFilterTransition';
import { defaultProps } from './constants/defaultProps';

interface FilterTransitionEmits {
  (e: 'change-id', id: FilterTransitionId): void;
}

const props = withDefaults(defineProps<FilterTransitionProps>(), {
  fadeInDuration: defaultProps.fadeInDuration,
  fadeOutDuration: defaultProps.fadeOutDuration,
});
const emit = defineEmits<FilterTransitionEmits>();

const {
  transitionDuration,
  transitionName,
  isFadeOutTransition,
  transitionStyles,

  transitionStart,
  fadeOutComplete,
  beforeFadeIn,
  transitionComplete,

  getActiveTransitionId,
  isActive,
  enable,
  disable,
} = useFilterTransition(props, {
  setActiveTransitionId(id: FilterTransitionId): void {
    emit('change-id', id);
  },
});

onDeactivated(() => {
  disable();
});

defineExpose<FilterTransitionElementRef>({
  getActiveTransitionId,
  isActive,
  enable,
  disable,
});
</script>

<template>
  <div v-auto-id="'FilterTransition'">
    <slot
      name="filter"
      :duration="transitionDuration"
      :before-leave="transitionStart"
      :after-leave="fadeOutComplete"
      :before-enter="beforeFadeIn"
      :after-enter="transitionComplete"
    />
    <slot name="before-content" />
    <Transition
      :name="transitionName"
      :enter-active-class="$style[`${transitionName}-enter-active`]"
      :enter-from-class="$style[`${transitionName}-enter-from`]"
      :enter-to-class="$style[`${transitionName}-enter-to`]"
      :leave-active-class="$style[`${transitionName}-leave-active`]"
      :leave-to-class="$style[`${transitionName}-leave-to`]"
      :leave-from-class="$style[`${transitionName}-leave-from`]"
    >
      <slot
        name="content"
        :is-fade-out="isFadeOutTransition"
        :extra-style="transitionStyles"
      />
    </Transition>
  </div>
</template>

<style lang="scss" module>
.fade-enter-active {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-leave-active {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
