import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { DateTimeRange } from 'web/src/modules/sportline/types';
import {
  defaultMapTabIdToKickoffPeriod,
  defaultTabIds,
} from 'web/src/modules/sportline/components/date-time-filter/constants';
import {
  DateTimeFilterTabId,
  SportFilterKickoffPeriod,
  SportlineType,
} from 'web/src/modules/sportline/enums';

export function findDefaultKickoffPeriodByTabId(
  kickoffPeriod: SportFilterKickoffPeriod,
): Optional<DateTimeFilterTabId> {
  return defaultTabIds.find((id) => defaultMapTabIdToKickoffPeriod[id] === kickoffPeriod);
}

export function getFilterSelectFilterPayload(
  id: DateTimeFilterTabId,
  options: {
    selectedRangeFrom: Optional<number>;
    selectedRangeTo: Optional<number>;
    lastSelectedCustomRange: DateTimeRange;
  },
): SportDateTimeFilter {
  switch (true) {
    case (id === DateTimeFilterTabId.DATE_RANGE): {
      return {
        activeKickoffPeriod: SportFilterKickoffPeriod.CUSTOM,
        customDateFrom: options.selectedRangeFrom ?? options.lastSelectedCustomRange.from,
        customDateTo: options.selectedRangeTo ?? options.lastSelectedCustomRange.to,
      };
    }

    case (id === DateTimeFilterTabId.LIVE): {
      return {
        activeKickoffPeriod: SportFilterKickoffPeriod.ALL,
        sportlineType: SportlineType.Live,
      };
    }

    default: {
      return {
        activeKickoffPeriod: defaultMapTabIdToKickoffPeriod[id] ?? SportFilterKickoffPeriod.ALL,
      };
    }
  }
}
