import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';

import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';

export function prepareNavigationBetweenPagesSportsFilter(
  filter: SportDateTimeFilter,
): SportDateTimeFilter {
  // @TODO check selected sportline type
  const {
    activeKickoffPeriod,
    customDateFrom,
    customDateTo,
  } = filter;

  return activeKickoffPeriod === SportFilterKickoffPeriod.CUSTOM
    ? {
        activeKickoffPeriod,
        customDateFrom,
        customDateTo,
      } : {
        activeKickoffPeriod,
      };
}
