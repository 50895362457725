import type { Ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { computed, toRef } from 'vue';

import type { Sport } from 'web/src/modules/sportline/types';
import type { SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import { SportlineType, SportSegmentId } from 'web/src/modules/sportline/enums';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import {
  resolveCybersportPageLink,
  resolveLivePageLink,
  resolveSportsPageLink,
} from 'web/src/modules/sportline/submodules/navigation/store/utils';

interface UseSportlineBreadcrumbsSportItemProps {
  sport?: Ref<Maybe<Sport>>;
  resolveLocationSportSegmentId?: Ref<Optional<SportSegmentId>>;
  resolveLocationSportlineType?: Ref<Optional<SportlineType>>;
}

interface UseSportlineBreadcrumbsSportItemComposable {
  canNavigateTo: Ref<boolean>;
  isShown: Ref<boolean>;
  title: Ref<string>;
  sportTreeElement: Ref<Maybe<SportsTreeSportElement>>;
  pageLocation: Ref<Optional<RouteLocationRaw>>;
  sportRepresentationFamily: Ref<Optional<string>>;
}

export function useSportlineBreadcrumbsSportItem(
  props: UseSportlineBreadcrumbsSportItemProps,
): UseSportlineBreadcrumbsSportItemComposable {
  const {
    resolveLocationSportSegmentId,
    resolveLocationSportlineType,
  } = props;
  const defaultSport = toRef(props.sport);

  const sportlineBreadcrumbsStore = useSportlineBreadcrumbsStore();
  const sportTreeElement = toRef(sportlineBreadcrumbsStore, 'sportElement');
  const sport = computed<Maybe<Sport>>(() => (defaultSport.value ?? sportTreeElement.value?.sport ?? null));

  const isShown = computed(() => !!sport.value);
  const title = computed(() => (sport.value?.name ?? ''));
  const sportRepresentationFamily = computed(() => sport.value?.representation.family);
  const canNavigateTo = computed(() => !sport.value?.segment.isVirtual);
  const pageLocation = computed<Optional<RouteLocationRaw>>(() => {
    if (resolveLocationSportSegmentId?.value === SportSegmentId.CyberSport) {
      return resolveCybersportPageLink({});
    }

    if (!sport.value) { return undefined; }

    if (resolveLocationSportlineType?.value === SportlineType.Live) {
      return resolveLivePageLink(sport.value?.navigationParameters || {});
    }

    return resolveSportsPageLink(sport.value?.navigationParameters || {});
  });

  return {
    canNavigateTo,
    isShown,
    title,
    sportTreeElement,
    pageLocation,
    sportRepresentationFamily,
  };
}
