import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  League,
  LeagueElement,
  Region,
  RegionElement,
  Sport,
  SportElement,
} from 'web/src/modules/sportline/types';
import type { CheckMaybe } from 'web/src/modules/sportline/types/utils';

interface UseFirstLeagueInSportElementProps<Element extends Maybe<SportElement>> {
  sportElement: Ref<Element>;
}

interface UseFirstElementsInSportElementComposable<Element extends Maybe<SportElement>> {
  regionElement: Ref<CheckMaybe<Element, RegionElement>>;
  leagueElement: Ref<CheckMaybe<Element, LeagueElement>>;
}

interface UseFirstLeagueInSportElementComposable<Element extends Maybe<SportElement>> {
  sport: Ref<CheckMaybe<Element, Sport>>;
  region: Ref<CheckMaybe<Element, Region>>;
  league: Ref<CheckMaybe<Element, League>>;
}

export function useFirstElementsInSportElement<Element extends Maybe<SportElement>>(
  props: UseFirstLeagueInSportElementProps<Element>,
): UseFirstElementsInSportElementComposable<Element> {
  const { sportElement } = props;

  const regionElement = computed(() => (sportElement.value?.regions[0] ?? null) as CheckMaybe<Element, RegionElement>);
  const leagueElement = computed(() => (regionElement.value?.leagues[0] ?? null) as CheckMaybe<Element, LeagueElement>);

  return { regionElement, leagueElement };
}

export function useFirstLeagueInSportElement<Element extends Maybe<SportElement>>(
  props: UseFirstLeagueInSportElementProps<Element>,
): UseFirstLeagueInSportElementComposable<Element> {
  const { sportElement } = props;

  const sport = computed(() => (sportElement.value?.sport ?? null) as CheckMaybe<Element, Sport>);
  const region = computed(() => (sportElement.value?.regions[0]?.region ?? null) as CheckMaybe<Element, Region>);
  const league = computed(() => (sportElement.value?.regions[0]?.leagues[0]?.league ?? null) as CheckMaybe<Element, League>);

  return { sport, region, league };
}
