import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type {
  CustomerSportListElement,
} from 'web/src/modules/sportline/submodules/favorites/types';
import type {
  SportsTreeSportElement,
} from 'web/src/modules/sportline/types/rest';
import { useCustomerLeaguesStore } from 'web/src/modules/sportline/submodules/favorites/store/leagues';
import {
  getFavoriteLeaguesFromSportList,
} from 'web/src/modules/sportline/submodules/favorites/utils';

interface SportlineSidebarFavoriteLeaguesProps {
  fullSportsList: Ref<SportsTreeSportElement[]>;
}

export interface SportlineSidebarFavoriteLeaguesComposable {
  fullFavoriteLeagues: Ref<readonly CustomerSportListElement[]>;
}

export function useSportlineSidebarFavoriteLeaguesComposable(
  props: SportlineSidebarFavoriteLeaguesProps,
): SportlineSidebarFavoriteLeaguesComposable {
  const customerLeaguesStore = useCustomerLeaguesStore();
  const customerLeaguesIds = toRef(customerLeaguesStore, 'customerLeaguesIds');

  const fullFavoriteLeagues = computed<readonly CustomerSportListElement[]>(() => {
    const favoriteLeaguesIds: readonly string[] = customerLeaguesIds.value ?? Object.freeze(new Array<string>());
    const result = props.fullSportsList.value
      ? getFavoriteLeaguesFromSportList(props.fullSportsList.value, favoriteLeaguesIds)
      : [];

    return Object.freeze<CustomerSportListElement[]>(result);
  });

  return {
    fullFavoriteLeagues,
  };
}
