<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';
import { computed, toRef } from 'vue';

import { IconDirection, IconSize } from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';

import { ChevronIcon } from '@components/chevron-icon';
import { VRouterLink } from '@components/link';
import $commonStyle from '@components/list/styles/common.module.scss';

import type { CustomerLeagueListElement } from 'web/src/modules/sportline/submodules/favorites/types';
import type { TopLeagueListElement } from 'web/src/modules/sportline/types';
import type { SportsTreeLeagueElement } from 'web/src/modules/sportline/types/rest';
import FavoriteLeagueIndicator from 'web/src/modules/sportline/submodules/favorites/views/FavoriteLeagueIndicator.vue';

type LeagueElement = TopLeagueListElement | SportsTreeLeagueElement | CustomerLeagueListElement;

interface LeagueListItemProps {
  sportId: string;
  leagueElement: LeagueElement;
  isRegionNameDisplayed?: boolean;
}

interface LeagueListItemEmits {
  (e: 'click', payload: { event: MouseEvent; leagueId: string }): void;
}

const props = defineProps<LeagueListItemProps>();
const emit = defineEmits<LeagueListItemEmits>();

const leagueElement = toRef(props, 'leagueElement');
const isRegionNameDisplayed = toRef(props, 'isRegionNameDisplayed');
const bgColor = computed(() => (isRegionNameDisplayed.value ? 'var(--Layer1)' : 'var(--Layer2)'));

const to = computed<RouteLocationRaw>(() => ({
  name: RouteName.SPORT_LEAGUE,
  params: { ...leagueElement.value.league.navigationParameters },
}));
const leagueId = computed(() => leagueElement.value.league.id);
const totalCounters = computed(() => leagueElement.value.counters.total);
const title = computed(() => {
  const regionName = leagueElement.value.region?.name;
  const leagueName = leagueElement.value.league.name;
  return isRegionNameDisplayed.value && regionName
    ? `${regionName} - ${leagueName}`
    : leagueName;
});

function onClick(event: MouseEvent): void {
  emit('click', { event, leagueId: leagueId.value });
}
</script>

<template>
  <li v-auto-id="'LeaguesListItem'"
    :class="[
      $commonStyle['list-item'],
      $style['leagues-list-item'],
    ]"
  >
    <VRouterLink
      :to="to"
      :class="[
        $commonStyle['list-item__inner'],
        $style['leagues-list-item__inner'],
      ]"
      @click="onClick"
    >
      <div
        :class="[
          $commonStyle['list-item__prefix'],
          $style['leagues-list-item__prefix'],
        ]"
      >
        <FavoriteLeagueIndicator
          :sport-id="sportId"
          :league-id="leagueId"
        />
      </div>
      <div
        :class="[
          $commonStyle['list-item__default'],
          $style['leagues-list-item__default'],
        ]"
      >
        <span>{{ title }}</span>
      </div>
      <div
        :class="[$commonStyle['list-item__suffix']]"
      >
        <div
          v-if="totalCounters > 0"
          :class="[$commonStyle['list-item__suffix-text']]"
        >
          {{ totalCounters }}
        </div>

        <div
          :class="[$commonStyle['list-item__chevron']]"
        >
          <ChevronIcon
            :direction="IconDirection.EAST"
            :size="IconSize.SIZE_16"
          />
        </div>
      </div>
    </VRouterLink>
  </li>
</template>

<style lang="scss" module>
@include for-layout using ($isDesktop) {
  .leagues-list-item {
    padding-right: 6px;
    margin-bottom: 2px;
    background-color: v-bind(bgColor);

    &__inner {
      grid-template-columns: if($isDesktop, 44px 1fr auto, 36px 1fr auto);
      width: 100%;
      padding-right: 8px;
    }

    &__default {
      padding-left: 0;
    }

    &__prefix {
      margin-right: if($isDesktop, 4px, 0);
      margin-left: if($isDesktop, 4px, 0);
    }
  }
}
</style>
