import type { Competitor } from 'web/src/modules/sportline/types';
import type { BetlineMatchStatisticsTeam } from 'web/src/modules/sportline/types/rest';

export function statisticTeamToCompetitor(team?: Maybe<BetlineMatchStatisticsTeam>): Maybe<Competitor> {
  if (!team?.id) { return null; }

  return {
    id: team.id,
    name: team.name || '',
    logo: team.logo || undefined,
  };
}
