import type { MenuLevelClickPayload } from 'web/src/components/SidebarMenu/types';
import type { CatalogClickPayload } from 'web/src/modules/sportline/types';
import { MenuLevel } from 'web/src/components/SidebarMenu/types';

export function getSidebarMenuCatalogClickPayload(
  payload: MenuLevelClickPayload,
): Maybe<CatalogClickPayload> {
  const { level, id } = payload;
  switch (level) {
    case MenuLevel.ONE:
      return { sportId: id };
    case MenuLevel.FREE:
      return { leagueId: id };
    default:
      return null;
  }
}
