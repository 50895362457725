import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { SportlinePageIdentifier } from 'web/src/modules/sportline/types/navigation';
import type {
  SportsTreeLeagueElement,
  SportsTreeRegionElement,
  SportsTreeSportElement,
} from 'web/src/modules/sportline/types/rest';
import useSportsTreeStore from 'web/src/modules/sportline/store/useSportsTreeStore';
import {
  leagueElementNavigationEqualsTo,
  regionElementNavigationEqualsTo,
  sportElementEqualsTo,
} from 'web/src/modules/sportline/utils/rest/compare';

interface UseSportlineTreeElementForPageIdentifierProps {
  pageIdentifier: Ref<SportlinePageIdentifier>;
}

interface UseSportlineTreeElementForPageIdentifierComposable {
  isLoaded: Ref<boolean>;
  isLoadingErrorOccurred: Ref<boolean>;
  sportElement: Ref<Maybe<SportsTreeSportElement>>;
  regionElement: Ref<Maybe<SportsTreeRegionElement>>;
  leagueElement: Ref<Maybe<SportsTreeLeagueElement>>;
  initialRequests(): Promise<void>;
}

// LEONWEB-2631 for details page used urlNames from page link
export function useSportlineTreeElementForPageIdentifier(
  props: UseSportlineTreeElementForPageIdentifierProps,
): UseSportlineTreeElementForPageIdentifierComposable {
  const { pageIdentifier } = props;

  const sportsTreeStore = useSportsTreeStore();
  const isLoaded = toRef(() => sportsTreeStore.isLoaded);
  const fullSportsList = toRef(() => sportsTreeStore.fullSportsList);
  const isLoadingErrorOccurred = toRef(() => sportsTreeStore.isLoadingErrorOccurred);
  const { reloadFullSportsList } = sportsTreeStore;

  const sportElement = computed<Maybe<SportsTreeSportElement>>(() => {
    const relatedSports = fullSportsList.value ?? [];
    const { sportFamily } = pageIdentifier.value;

    if (!sportFamily) {
      return null;
    }

    return relatedSports.find(sportElementEqualsTo(sportFamily, (sport) => (
      sportFamily.toLowerCase() === sport?.sport.representation.family.toLowerCase()
    ))) ?? null;
  });

  const regionElement = computed<Maybe<SportsTreeRegionElement>>(() => {
    const relatedRegions = sportElement.value?.regions ?? [];
    const { regionId, regionUrlName } = pageIdentifier.value;

    if (!regionId && !regionUrlName) {
      return null;
    }

    return relatedRegions.find(regionElementNavigationEqualsTo(regionId || regionUrlName)) ?? null;
  });

  const leagueElement = computed<Maybe<SportsTreeLeagueElement>>(() => {
    const relatedLeagues = regionElement.value?.leagues ?? [];
    const { leagueId, leagueUrlName } = pageIdentifier.value;

    if (!leagueId && !leagueUrlName) {
      return null;
    }

    return relatedLeagues.find(leagueElementNavigationEqualsTo(leagueId || leagueUrlName)) ?? null;
  });

  async function initialRequests(): Promise<void> {
    if (isLoaded.value) {
      return;
    }

    await reloadFullSportsList({ silent: true, invalidateAfterInterval: 10_000 });
  }

  return {
    isLoaded,
    isLoadingErrorOccurred,
    sportElement,
    regionElement,
    leagueElement,
    initialRequests,
  };
}
