import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import type {
  SportFamily,
} from 'web/src/modules/sportline/enums';
import type {
  RegionElement,
  SportElement,
} from 'web/src/modules/sportline/types';
import type {
  CoreSportlineFetchOptions,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import type {
  SportSegmentSettings,
} from 'web/src/modules/sportline/types/settings';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useSportlineEventsChangesListeners } from 'web/src/modules/sportline/store/composables';
import { updateSegmentEventsResponse } from 'web/src/modules/sportline/submodules/segment/store/utils';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';

interface SegmentStoreLiveProps {
  allLiveTabUpdateInterval: Ref<number>;
  selectedLiveTabUpdateInterval: Ref<number>;
  sportSegmentsSettings: Ref<SportSegmentSettings>;
  filterOptionsIds: Ref<string[]>;
  activeSportElement: Ref<Maybe<SportElement>>;
  activeRegionElement: Ref<Maybe<RegionElement>>;
}

export interface SegmentStoreLiveComposable {
  liveListUpdateInterval: Ref<number>;
  isReady: Ref<boolean>;
  initialRequests(): Promise<void>;
  onInit(): void;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
  syncBackgroundRequests(options: {
    force?: boolean;
    silent?: boolean;
  }): Promise<void>;
  // @TODO don't export
  rawLiveEventsResponse: Ref<Maybe<Readonly<GetSportEventsResponse>>>;
}

type FetchOptions = CoreSportlineFetchOptions & {
  regionId?: string;
  sportFamily?: SportFamily | string;
};

export default function useSegmentStoreLiveComposable(
  props: SegmentStoreLiveProps,
): SegmentStoreLiveComposable {
  const {
    sportSegmentsSettings,
    allLiveTabUpdateInterval,
    selectedLiveTabUpdateInterval,
  } = props;

  const apiService = useSportlineApiService();
  const broadcastSelected = useBroadcastSelected();

  const isUpdateOnlyActiveEntityOnChangeEnabled = toRef(() => sportSegmentsSettings.value.isUpdateOnlyActiveEntityOnChangeEnabled);
  /** Update only one sport/region for active tab */
  const isUpdateOnlyActiveEntityEnabled = toRef(() => sportSegmentsSettings.value.isUpdateOnlyActiveEntityEnabled);

  const rawLiveEventsResponse = ref<Maybe<Readonly<GetSportEventsResponse>>>(null);
  const lastUpdate = new BackgroundUpdateStopwatch<['segmentLiveEvents']>({
    segmentLiveEvents: async ({ silent, regionId, sportFamily }: FetchOptions) => {
      const response = await apiService.loadLiveEvents({
        vTag: rawLiveEventsResponse.value?.vtag,
        sportFamily: sportFamily || sportSegmentsSettings.value.sportFamily,
        doAddUpcoming: !!sportSegmentsSettings.value.isUpcomingEventsEnabled,
        regionId,
        silent,
      });

      const group = sportFamily || regionId;
      if (group) {
        rawLiveEventsResponse.value = updateSegmentEventsResponse(
          rawLiveEventsResponse.value,
          response,
          { sportFamily, regionId },
        );
        broadcastSelected.updateDataInStorageByResponse({ response });
        lastUpdate.updateGroup('segmentLiveEvents', group);
      } else {
        rawLiveEventsResponse.value = updateSegmentEventsResponse(
          rawLiveEventsResponse.value,
          response,
          {},
        );
        broadcastSelected.updateDataInStorageByResponse({ response });
        lastUpdate.update('segmentLiveEvents');
        lastUpdate.updateGroup('segmentLiveEvents', props.filterOptionsIds.value);
      }
    },
  });

  const segmentSportFamily = computed(() => props.sportSegmentsSettings.value.sportFamily);

  const liveListUpdateInterval = computed<number>(() => (
    isUpdateOnlyActiveEntityEnabled.value
      ? selectedLiveTabUpdateInterval.value
      : allLiveTabUpdateInterval.value
  ));
  const isReady = computed(() => rawLiveEventsResponse.value !== null);
  const isNeedToUpdateFullList = computed<boolean>(() => (
    lastUpdate.isIntervalElapsed('segmentLiveEvents', allLiveTabUpdateInterval.value)
  ));
  const selectedEntityId = computed<{ sportFamily?: string; regionId?: string }>(
    () => (segmentSportFamily.value
      ? { regionId: props.activeRegionElement.value?.region.id }
      : { sportFamily: props.activeSportElement.value?.sport.representation.family }),
  );
  const willUpdateFullList = computed<boolean>(() => (
    // update time elapsed
    isNeedToUpdateFullList.value
    // disabled one entity updates
    || !isUpdateOnlyActiveEntityEnabled.value
    // don't have selected id
    || (!selectedEntityId.value.sportFamily && !selectedEntityId.value.regionId)
  ));

  const {
    initialRequests,
    syncState: internalSyncState,
    syncBackgroundRequests: internalSyncBackgroundRequests,
    setBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval: liveListUpdateInterval,
  });
  const {
    onInit: sportlineEventsChangesListenersOnInit,
  } = useSportlineEventsChangesListeners({
    setResponse(response: GetSportEventsResponse): void {
      rawLiveEventsResponse.value = response;
    },
    getResponse(): Maybe<GetSportEventsResponse> {
      return rawLiveEventsResponse.value;
    },
  });

  function onInit(): void {
    sportlineEventsChangesListenersOnInit();
    useSyncSportline(async (silent: boolean): Promise<void> => {
      if (!isUpdateOnlyActiveEntityOnChangeEnabled.value) { return internalSyncState({ silent }); }
      if (willUpdateFullList.value) { return internalSyncState({ silent }); }
      const group = selectedEntityId.value.sportFamily || selectedEntityId.value.regionId;
      return internalSyncState(
        { silent, groups: { segmentLiveEvents: group } },
        { segmentLiveEvents: selectedEntityId.value },
      );
    }, liveListUpdateInterval);
  }

  async function syncBackgroundRequests(options: {
    force?: boolean;
    silent?: boolean;
  }): Promise<void> {
    // check update full list
    if (!isUpdateOnlyActiveEntityOnChangeEnabled.value) { return internalSyncBackgroundRequests(options); }
    if (willUpdateFullList.value) { return internalSyncBackgroundRequests(options); }
    const group = selectedEntityId.value.sportFamily || selectedEntityId.value.regionId;
    return internalSyncBackgroundRequests(
      { ...options, groups: { segmentLiveEvents: group } },
      { segmentLiveEvents: selectedEntityId.value },
    );
  }

  return {
    liveListUpdateInterval,
    isReady,
    rawLiveEventsResponse,
    initialRequests,
    onInit,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  };
}
