import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { getBetgeniusWidgetConfiguration } from '@leon-hub/api-sdk';
import { logger } from '@leon-hub/logging';

import { useGraphqlClient } from '@core/app-rest-client';

import type {
  BetgeniusWidgetConfig,
  BetgeniusWidgetFrameConfig,
} from '../types';

interface UseBetgeniusConfigProps {
  iframeUrl: Ref<Optional<string>>;
}

interface UseBetgeniusConfigComposable {
  config: Ref<Maybe<BetgeniusWidgetConfig>>;
  configFinal: Ref<Maybe<BetgeniusWidgetFrameConfig>>;
  fetchConfig(): Promise<void>;
}

export function useBetgeniusConfig(
  props: UseBetgeniusConfigProps,
): UseBetgeniusConfigComposable {
  const { iframeUrl } = props;

  const api = useGraphqlClient();

  const config = ref<Maybe<BetgeniusWidgetConfig>>(null);

  const configFinal = computed<Maybe<BetgeniusWidgetFrameConfig>>(() => (
    config.value
      ? {
          ...config.value,
          widgetLocales: [{
            locale: 'en-gb',
            default: true,
          }, {
            locale: 'ru-ru',
          }, {
            locale: 'it-it',
          }],
          iframeUrl: iframeUrl.value,
        }
      : null
  ));

  async function fetchConfig(): Promise<void> {
    try {
      config.value = await getBetgeniusWidgetConfiguration(
        api,
        (node) => node.queries.configContent.getBetgeniusWidgetConfiguration,
        { options: {} },
      );
    } catch (error) {
      logger.error('An error occurred while updating Betgenius config', error);
    }
  }

  return {
    config,
    configFinal,
    fetchConfig,
  };
}
