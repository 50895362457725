<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';

import { VRouterLink } from '@components/link';
import { VListItem } from '@components/list';

interface BreadcrumbsRelatedListItemProps {
  isActive?: boolean;
  title: string;
  to: RouteLocationRaw;
}

interface BreadcrumbsRelatedListItemEmits {
  (e: 'link-click'): void;
}

defineProps<BreadcrumbsRelatedListItemProps>();
const emit = defineEmits<BreadcrumbsRelatedListItemEmits>();
</script>

<template>
  <VListItem v-auto-id="'BreadcrumbsRelatedListItem'"
    :is-active="isActive"
    :class="[
      $style['breadcrumbs-related-list-item'],
      $style['breadcrumbs-related-list-item--borderless'],
    ]"
  >
    <VRouterLink
      :to="to"
      :class="$style['breadcrumbs-related-list-item__link']"
      @click="emit('link-click')"
    >
      {{ title }}
    </VRouterLink>
  </VListItem>
</template>

<style module lang="scss">
.breadcrumbs-related-list-item {
  @include regular\13\16;

  min-height: 40px;
  margin-bottom: 0;
  background-color: transparent;

  // @hint --borderless class is used to add weight for local rules instead VListItem
  &--borderless#{&}:first-child,
  &--borderless#{&}:first-child:last-of-type, {
    border-top-left-radius: 0;
  }

  &--borderless#{&}:not(:last-of-type) {
    margin-bottom: 0;
  }

  &__link {
    display: block;
    padding: 12px;
    color: var(--Layer1);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.15s $animationCubicBezier, background-color 0.15s $animationCubicBezier;

    &:hover {
      color: var(--TextDefault);
      background-color: var(--Highlight);
    }
  }
}
</style>
