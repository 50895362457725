<script lang="ts" setup>
import type { DatePickerInstance } from '@vuepic/vue-datepicker';
import {
  defineAsyncComponent,
  onBeforeMount,
  ref,
  toRef,
} from 'vue';

import { TimeFormats } from '@leon-hub/utils';

import { useI18nLocale } from '@core/i18n';
import { useTheme } from '@core/theme';

import type { VDatepickerEmits, VDatepickerProps } from './types';
import { useVDatepicker } from './composables';

const props = defineProps<VDatepickerProps>();

const emit = defineEmits<VDatepickerEmits>();

const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));
const min = toRef(props, 'min');
const max = toRef(props, 'max');
const roundTo = toRef(props, 'roundTo');

const format = TimeFormats.dayMonthYear;

const {
  minDate,
  maxDate,
  onChange,
} = useVDatepicker({ min, max, roundTo }, emit);

const datepicker = ref<DatePickerInstance>(null);

const { isDark } = useTheme();
const { lang } = useI18nLocale();

onBeforeMount(() => {
  void import('@vuepic/vue-datepicker/dist/main.css');
});

defineExpose({
  open: () => datepicker.value?.openMenu(),
});
</script>

<template>
  <div v-auto-id="'VDatepicker'" :class="$style['date-picker']">
    <VueDatePicker
      ref="datepicker"
      :model-value="value"
      :format="format"
      :enable-time-picker="false"
      :min-date="minDate"
      :max-date="maxDate"
      :dark="isDark"
      :locale="lang"
      :placeholder="placeholder"
      teleport
      auto-apply
      :transitions="false"
      @update:model-value="onChange"
    />
  </div>
</template>

<style module lang="scss">
.date-picker {
  :global(.dp__main) {
    display: inline-flex;
    width: auto;
  }

  :global(.dp__input) {
    @include regular\14\16\025;

    width: 74px;
    padding: 0;
    margin: 0;
    font-family: var(--MainFontFamily);
    color: var(--TextDefault);
    background-color: transparent;
    border: none;
    border-radius: 0;
    transition: none;
  }

  :global(.dp--clear-btn) {
    display: none;
  }

  :global(.dp__input_icon) {
    display: none;
  }
}
</style>
