<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';

import { ListType, VList } from '@components/list';

import type { SportsTreeRegionElement } from 'web/src/modules/sportline/types/rest';
import { BreadcrumbType } from 'web/src/components/Breadcrumbs/VBreadcrumb/enums';
import VBreadcrumb from 'web/src/components/Breadcrumbs/VBreadcrumb/VBreadcrumb.vue';
import BreadcrumbsRelatedListItem
  from 'web/src/modules/sportline/submodules/breadcrumbs/components/BreadcrumbsRelatedListItem.vue';
import { BreadcrumbId } from 'web/src/modules/sportline/submodules/breadcrumbs/enums';
import { resolveRegionPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';

interface RegionBreadcrumbsItemProps {
  activeRegionId?: Maybe<string>;
  relatedRegions: SportsTreeRegionElement[];
  title: string;
  position: number;
  open: boolean;
  to?: RouteLocationRaw;
}

interface RegionBreadcrumbsItemEmits {
  (e: 'toggle', id: BreadcrumbId): void;
}

defineProps<RegionBreadcrumbsItemProps>();
const emit = defineEmits<RegionBreadcrumbsItemEmits>();
</script>

<template>
  <VBreadcrumb v-auto-id="'RegionBreadcrumbsItem'"
    :type="BreadcrumbType.MENU"
    :title="title"
    :position="position"
    :open="open"
    :to="to"
    :can-expand="relatedRegions.length > 0"
    controlled
    @toggle="emit('toggle', BreadcrumbId.Regions)"
  >
    <template #default="{ forceHide }">
      <VList :list-type="ListType.GROUP">
        <BreadcrumbsRelatedListItem
          v-for="relatedRegion in relatedRegions"
          :key="`region-breadcrumb-${relatedRegion.region.id}`"
          :is-active="!!activeRegionId && activeRegionId === relatedRegion.region.id"
          :title="relatedRegion.region.name"
          :to="resolveRegionPageLink(relatedRegion.region.navigationParameters)"
          @link-click="forceHide"
        />
      </VList>
    </template>
  </VBreadcrumb>
</template>
