import type { Ref } from 'vue';
import { watch } from 'vue';

import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';

interface UseLogErrorOnceForValueComposable {
  logError(error: unknown): void;
}

export function useLogErrorOnceForValue(valueRef: Ref<unknown>): UseLogErrorOnceForValueComposable {
  let canLog = true;

  function logError(error: unknown): void {
    if (!canLog) { return; }
    logger.error(normalizeError(error));
    canLog = false;
  }

  watch(valueRef, () => { canLog = true; }, { immediate: true });

  return { logError };
}
