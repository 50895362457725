import type { Ref } from 'vue';
import { nextTick, ref } from 'vue';

import { DateTime, DateTimeInterval } from '@leon-hub/utils';

import type { VTabsReference } from 'web/src/components/Tabs';
import type {
  SportFilterKickoffPeriod,
  SportlineType,
} from 'web/src/modules/sportline/enums';
import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { DateTimeRange } from 'web/src/modules/sportline/types';
import { getFilterSelectFilterPayload } from 'web/src/modules/sportline/components/date-time-filter/utils';
import {
  DateTimeFilterTabId,
  FocusedInput,
} from 'web/src/modules/sportline/enums';

import { useDateTimeFilterWatchProps } from './useDateTimeFilterWatchProps';

interface UseDateTimeFilterProps {
  activeKickoffPeriod: Ref<SportFilterKickoffPeriod>;
  activeSportlineType: Ref<Optional<SportlineType>>;
  selectedRangeFrom: Ref<Optional<number>>;
  selectedRangeTo: Ref<Optional<number>>;
}

export interface DateTimeFilterEmits {
  (e: 'select-range', range: DateTimeRange): void;
  (e: 'select-filter', filter: SportDateTimeFilter): void;
}

interface UseDateTimeFilterComposable {
  tabsRef: Ref<Optional<VTabsReference>>;
  showSelectCustomRangeForm: Ref<boolean>;
  focusedLocal: Ref<FocusedInput>;
  preparedForActivateTab: Ref<DateTimeFilterTabId>;
  slideToActive(): void;
  setFocused(state: FocusedInput): void;
  onActivateTabClick(to: DateTimeFilterTabId): void;
  hideSelectCustomRange(): Promise<void>;
  rangeFromChanged({ timestamp }: { timestamp?: number }): void;
  rangeToChanged({ timestamp }: { timestamp?: number }): void;
}

function createInitialDateRange(): DateTimeRange {
  const initialDateRange = DateTimeInterval.fromNowUntilTheEndOfTheDay();
  return {
    from: initialDateRange.from.toTimestamp(),
    to: initialDateRange.to.toTimestamp(),
  };
}

export function useDateTimeFilter(
  props: UseDateTimeFilterProps,
  emit: DateTimeFilterEmits,
): UseDateTimeFilterComposable {
  const {
    activeKickoffPeriod,
    activeSportlineType,
    selectedRangeFrom,
    selectedRangeTo,
  } = props;

  let backToTabAfterCustomRange = DateTimeFilterTabId.ALL;
  let lastSelectedCustomRange: DateTimeRange = createInitialDateRange();

  const tabsRef = ref<Optional<VTabsReference>>();
  const showSelectCustomRangeForm = ref(false);
  const focusedLocal = ref(FocusedInput.NONE);
  const preparedForActivateTab = ref(DateTimeFilterTabId.ALL);

  function selectDateTimeRange(range: DateTimeRange): void {
    lastSelectedCustomRange = range;
    emit('select-range', range);
  }

  function slideToActive(): void {
    tabsRef.value?.slideToActive();
  }

  function setFocused(state: FocusedInput): void {
    focusedLocal.value = state;
  }

  function emitSelectFilterForTabId(id: DateTimeFilterTabId): void {
    emit('select-filter', getFilterSelectFilterPayload(id, {
      selectedRangeFrom: selectedRangeFrom.value,
      selectedRangeTo: selectedRangeTo.value,
      lastSelectedCustomRange,
    }));
  }

  function setActiveTab(to: DateTimeFilterTabId, from: Maybe<DateTimeFilterTabId>): void {
    if (to === from) {
      // @TODO check extra setActiveTab
      return;
    }

    if (to === DateTimeFilterTabId.DATE_RANGE) {
      showSelectCustomRangeForm.value = true;
      backToTabAfterCustomRange = from && from !== DateTimeFilterTabId.DATE_RANGE
        ? from
        : DateTimeFilterTabId.ALL;
    } else {
      showSelectCustomRangeForm.value = false;
    }

    preparedForActivateTab.value = to;
  }

  function onActivateTabClick(to: DateTimeFilterTabId): void {
    setActiveTab(to, preparedForActivateTab.value);
    emitSelectFilterForTabId(to);
  }

  async function hideSelectCustomRange(): Promise<void> {
    setActiveTab(backToTabAfterCustomRange, preparedForActivateTab.value);
    emitSelectFilterForTabId(backToTabAfterCustomRange);

    await nextTick();
    tabsRef.value?.scrollToSlide();
  }

  async function clearCustomRange(): Promise<void> {
    lastSelectedCustomRange = createInitialDateRange();
    await hideSelectCustomRange();
  }

  function rangeFromChanged({ timestamp }: { timestamp?: number }): void {
    if (!timestamp) {
      void clearCustomRange();
      return;
    }

    const selectedTo = selectedRangeTo.value ? Math.max(selectedRangeTo.value, timestamp) : timestamp;
    const to = DateTime.roundToDayEnd(selectedTo);

    selectDateTimeRange({ from: timestamp, to });
    setFocused(FocusedInput.NONE);
  }

  function rangeToChanged({ timestamp }: { timestamp?: number }): void {
    if (!timestamp) {
      void clearCustomRange();
      return;
    }

    // @TODO remove round to logic, it must be implemented in the datetime picker
    const selectedFrom = selectedRangeFrom.value ? Math.min(selectedRangeFrom.value, timestamp) : timestamp;
    const minFrom = DateTime.now().toTimestamp();
    const rounded = DateTime.roundToDayStart(selectedFrom);
    const from = Math.max(rounded, minFrom);

    selectDateTimeRange({ from, to: timestamp });
    setFocused(FocusedInput.NONE);
  }

  useDateTimeFilterWatchProps({
    activeKickoffPeriod,
    activeSportlineType,
    preparedForActivateTab,
    setActiveTab,
  });

  return {
    tabsRef,
    showSelectCustomRangeForm,
    focusedLocal,
    preparedForActivateTab,
    slideToActive,
    setFocused,
    onActivateTabClick,
    hideSelectCustomRange,
    rangeFromChanged,
    rangeToChanged,
  };
}
