<script lang="ts" setup>
/**
 * The main sportline component. It holds sport header and region blocks.
 */
import { ref, toRef } from 'vue';

import type { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event';
import type { SportElement, SportEventsListSportElementProperties } from 'web/src/modules/sportline/types';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';
import type { UpcomingLabelType } from 'web/src/modules/sportline/views/EventBlock/enums';
import { useExpandableSportlineBlock } from 'web/src/modules/sportline/composables/list';
import { useSportlineSportRegionElements } from 'web/src/modules/sportline/composables/list-components/sport';
import SportHeadline from 'web/src/modules/sportline/views/headline/SportHeadline.vue';
import SportlineRegionBlock from 'web/src/modules/sportline/views/RegionBlock/SportlineRegionBlock.vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';

import { useSportlineBlockHeadline } from './composables';

interface SportlineSportBlockProps extends SportEventsListSportElementProperties {
  sportElement: SportElement;
  withoutHeadline?: boolean;
  isShowAddLeagueToFavorite?: boolean;
  isHideLeagueInfo?: boolean;
  isColumnNamesDisplayDisabled?: boolean;
  isDisplayMainEventsDisabled?: boolean;
  isDisplayOutrightHeadlineDisabled?: boolean;
  isDisplayOutrightEventsEnabled?: boolean;
  isLeagueDefaultExpanded?: boolean;
  canAddToFavorite?: boolean;
  canAddOutrightsToFavorite?: boolean;
  upcomingLabelType?: Maybe<UpcomingLabelType>;
  isComingHeadline?: boolean;
  isMarketTypesSelectionEnabled?: boolean;
  collapseLeagueKey?: Maybe<string>;
  customTitle?: Maybe<string>;
  activeEventId?: Maybe<string>;
  basisKey: SportlineFragmentBasisKey;
  // expandable
  expandKey?: Maybe<string>;
  isDefaultExpanded?: boolean;
  isFilterActive?: boolean;
  extendedBlockType?: ExtendedEventBlock;
}

interface SportlineSportBlockEmits {
  (e: 'click-event'): void;
}

const props = withDefaults(defineProps<SportlineSportBlockProps>(), {
  collapseLeagueKey: null,
  expandKey: null,
});
const emit = defineEmits<SportlineSportBlockEmits>();

const content = ref<Optional<HTMLDivElement>>();
const sportElement = toRef(props, 'sportElement');
const basisKey = toRef(props, 'basisKey');

const {
  sport,
  sportId,
  regionElements,
} = useSportlineSportRegionElements({ sportElement });
const {
  headlineKey,
  eventsCount,
} = useSportlineBlockHeadline({
  withoutHeadline: toRef(props, 'withoutHeadline', false),
  isMarketTypesSelectionEnabled: toRef(props, 'isMarketTypesSelectionEnabled', false),
  sportElement: toRef(props, 'sportElement'),
  sportId,
  basisKey,
});
const {
  isExpanded,
  isExpandable,
  expandAnimationActive,
  expandAnimationDuration,
  toggleExpandState,
} = useExpandableSportlineBlock({
  content,
  expandKey: toRef(props, 'expandKey'),
  isDefaultExpanded: toRef(props, 'isDefaultExpanded'),
});
</script>

<template>
  <div v-auto-id="'SportlineSportBlock'"
    v-if="regionElements.length > 0"
    :key="`group-${sport.id}`"
  >
    <SportHeadline
      v-if="!withoutHeadline"
      :key="headlineKey"
      v-data-test-unit="{ 'el': 'sportline-sport-block-headline', 'is-expanded': isExpanded }"
      :sport="sport"
      :basis-key="basisKey"
      :count-events="eventsCount"
      :is-coming="isComingHeadline"
      :expand-key="expandKey"
      :is-expanded="expandAnimationActive ? !isExpanded : isExpanded"
      :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
      :custom-title="customTitle"
      :class="$style['sport-events-list-sport__headline']"
      @toggle-expand-state="toggleExpandState"
    />

    <div
      ref="content"
      v-data-test-unit="{ el: 'sportline-sport-block-content' }"
      :class="{
        [$style['expandable-element-content']]: true,
        [$style['expandable-element-content--hidden']]: isExpandable && !isExpanded,
      }"
      :style="{ 'transition-duration': `${expandAnimationDuration}ms` }"
    >
      <SportlineRegionBlock
        v-for="regionElement in regionElements"
        :key="regionElement.key"
        :sport="sport"
        :region-element="regionElement"
        :upcoming-label-type="upcomingLabelType"
        :active-event-id="activeEventId"
        :is-hide-league-info="isHideLeagueInfo"
        :is-show-add-league-to-favorite="isShowAddLeagueToFavorite"
        :is-column-names-display-disabled="isColumnNamesDisplayDisabled"
        :is-display-main-events-disabled="isDisplayMainEventsDisabled"
        :is-display-outright-headline-disabled="isDisplayOutrightHeadlineDisabled"
        :is-display-outright-events-enabled="isDisplayOutrightEventsEnabled"
        :can-add-to-favorite="canAddToFavorite"
        :can-add-outrights-to-favorite="canAddOutrightsToFavorite"
        :collapse-league-key="collapseLeagueKey"
        :is-league-default-expanded="isLeagueDefaultExpanded"
        :is-filter-active="isFilterActive"
        :extended-block-type="extendedBlockType"
        :basis-key="basisKey"
        @click-event="emit('click-event')"
      />
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/styles/expandable-element';

.sport-events-list-sport {
  &__headline {
    background-color: var(--Layer0);
  }
}
</style>
