<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import { VCountryFlagSize } from '@components/country-flag';
import { VLinkPrevented } from '@components/link';
import { VIcon } from '@components/v-icon';

import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { SportsTreeRegionElement, SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import { VCover } from 'web/src/components/Cover';
import RegionLogo from 'web/src/modules/sportline/components/SportlineLogo/RegionLogo.vue';
import { SportSegmentId } from 'web/src/modules/sportline/enums';
import { resolveRegionPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';

import LeaguesList from '../leagues-list/LeaguesList.vue';

interface RegionsListProps {
  sportElement: SportsTreeSportElement;
  regionElement: SportsTreeRegionElement;
  dateTimeFilter: SportDateTimeFilter;
  isCyberSportPage?: boolean;
  isChildRoute?: boolean;
  isOpen?: boolean;
}

interface RegionSpoilerEmits {
  (e: 'change-open-state', value: boolean): void;
}

defineProps<RegionsListProps>();
const emit = defineEmits<RegionSpoilerEmits>();
</script>

<template>
  <div v-auto-id="'RegionSpoiler'">
    <VCover
      :opened="isOpen"
      @change="emit('change-open-state', $event)"
    >
      <template #default="coverScope">
        <div
          :class="{
            [$style['sports-level-1-spoiler']]: true,
            [$style['sports-level-1-spoiler__cover']]: true,
            [$style['sports-level-1-spoiler--default']]: true,
            [$style['sports-level-1-spoiler--open']]: coverScope.isOpen,
          }"
        >
          <div
            :class="{
              [$style['sports-level-1-spoiler__header']]: true,
              [$style['sports-level-1-spoiler__header--close']]: !coverScope.isOpen,
              [$style['sports-level-1-spoiler__header--open']]: coverScope.isOpen,
            }"
          >
            <RegionLogo
              :size="IconSize.SIZE_20"
              :flag-size="VCountryFlagSize.SIZE_16"
              :country-code="regionElement.region.representation.icon"
              :sport-segment-id="isCyberSportPage ? SportSegmentId.CyberSport : undefined"
              :class="$style['region-icon']"
            />

            <VLinkPrevented
              :class="{
                [$style['sports-level-1-spoiler__title']]: true,
                [$style['sports-level-1-spoiler__title--open']]: coverScope.isOpen,
              }"
              :to="resolveRegionPageLink(regionElement.region.navigationParameters)"
              @click="coverScope.toggle"
            >
              {{ regionElement.region.name }}
            </VLinkPrevented>

            <span
              :class="$style['sports-level-1-spoiler__indicator']"
              @click="coverScope.toggle"
            >
              <VIcon
                :size="IconSize.SIZE_16"
                :name="coverScope.isOpen ? IconName.EXPAND_UP : IconName.EXPAND_DOWN"
              />
            </span>
          </div>

          <div
            v-if="coverScope.isLoaded"
            v-show="coverScope.isOpen"
            :class="$style['sports-level-1-spoiler__content']"
          >
            <LeaguesList
              :sport="sportElement.sport"
              :leagues="regionElement.leagues"
              :date-time-filter="dateTimeFilter"
            />
          </div>
        </div>
      </template>
    </VCover>
  </div>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/styles/sport-spoiler';

.region-icon {
  margin-left: 8px;
}
</style>
