import type { IconNameType } from '@leon-hub/icons';
import { IconSize } from '@leon-hub/icons';

import type { TopLeagueListElement } from 'web/src/modules/sportline/types';
import type { SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import { resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { extractTopLeagues, sortTopLeagueElementsASC } from 'web/src/modules/sportline/utils';

import type { FilterTab } from '../types';
import { prepareTopLeagueListElementsForFilterTab } from './prepareTopLeagueListElementsForFilterTab';

export function createFilterTabForSportElement(
  sportElement: SportsTreeSportElement,
  isActive = false,
): Maybe<FilterTab> {
  const sportTopLeagues: TopLeagueListElement[] = extractTopLeagues(sportElement);

  if (!sportTopLeagues.length) { return null; }

  return {
    key: sportElement.sport.id,
    sport: sportElement.sport,
    isActive,
    leagues: prepareTopLeagueListElementsForFilterTab(sportTopLeagues).sort(sortTopLeagueElementsASC),
    totalCount: sportElement.leaguesCount,
    allLeaguesLocation: resolveSportsPageLink(sportElement.sport.navigationParameters),
    tabItem: {
      id: sportElement.sport.id,
      icon: {
        name: `sport-${sportElement.sport.representation.family}` as IconNameType,
        size: IconSize.SIZE_16,
      },
    },
  };
}
