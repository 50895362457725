import type { Ref } from 'vue';
import { computed } from 'vue';

import type { RegionElement, Sport, SportElement } from 'web/src/modules/sportline/types';
import { filterEmptyRegionElements } from 'web/src/modules/sportline/utils';

interface UseSportlineSportRegionElementsProps {
  sportElement: Ref<SportElement>;
}

interface UseSportlineSportRegionElementsComposable {
  sport: Ref<Sport>;
  sportId: Ref<string>;
  regionElements: Ref<RegionElement[]>;
}

export function useSportlineSportRegionElements(
  props: UseSportlineSportRegionElementsProps,
): UseSportlineSportRegionElementsComposable {
  const { sportElement } = props;

  const sport = computed<Sport>(() => sportElement.value.sport);
  const sportId = computed<string>(() => sport.value.id);
  const regionElements = computed<RegionElement[]>(() => filterEmptyRegionElements(sportElement.value.regions));

  return {
    sport,
    sportId,
    regionElements,
  };
}
