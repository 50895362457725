import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportElement, SportId } from 'web/src/modules/sportline/types';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';
import { useProvideMarketColumn } from 'web/src/modules/sportline/composables/list';
import {
  useSportlineElementMarketColumns,
  useSportlineElementMarketTypes,
} from 'web/src/modules/sportline/composables/markets';
import { useSportlineCacheStorage } from 'web/src/modules/sportline/submodules/cache';
import { getSportElementEventsCounters } from 'web/src/modules/sportline/utils';

interface UseSportlineBlockHeadlineProps {
  basisKey: Ref<SportlineFragmentBasisKey>;
  sportId: Ref<SportId>;
  // @TODO remove passing full sportElement and get  data from cache
  sportElement: Ref<SportElement>;
  withoutHeadline: Ref<boolean>;
  isMarketTypesSelectionEnabled: Ref<boolean>;
}

interface UseSportlineBlockHeadlineComposable {
  headlineKey: Ref<string>;
  eventsCount: Ref<number>;
}

export function useSportlineBlockHeadline(
  props: UseSportlineBlockHeadlineProps,
): UseSportlineBlockHeadlineComposable {
  const {
    isMarketTypesSelectionEnabled,
    sportId,
    sportElement,
    basisKey,
  } = props;

  const { getSportCache } = useSportlineCacheStorage();
  const sportElementCache = computed(() => getSportCache(sportId.value).value);

  const headlineKey = computed<string>(() => (sportElementCache.value
    ? `${sportElementCache.value.sport.id}_${sportElementCache.value.updated}`
    : sportId.value));
  const eventsCount = computed<number>(() => {
    const counters = getSportElementEventsCounters(sportElement.value);
    return counters.total;
  });

  const { marketsColumns } = useSportlineElementMarketColumns({ sportlineElement: sportElement });
  const { marketsTypes } = useSportlineElementMarketTypes({ sportlineElement: sportElement });

  useProvideMarketColumn({
    basisKey,
    isMarketTypesSelectionEnabled,
    marketsColumns,
    marketsTypes,
  });

  return {
    headlineKey,
    eventsCount,
  };
}
