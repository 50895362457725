<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';

import { ListType, VList } from '@components/list';

import type { SportsTreeLeagueElement } from 'web/src/modules/sportline/types/rest';
import { BreadcrumbType } from 'web/src/components/Breadcrumbs/VBreadcrumb/enums';
import VBreadcrumb from 'web/src/components/Breadcrumbs/VBreadcrumb/VBreadcrumb.vue';
import BreadcrumbsRelatedListItem
  from 'web/src/modules/sportline/submodules/breadcrumbs/components/BreadcrumbsRelatedListItem.vue';
import { BreadcrumbId } from 'web/src/modules/sportline/submodules/breadcrumbs/enums';
import { resolveLeaguePageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';

interface LeagueBreadcrumbsItemProps {
  leagueElement: Maybe<SportsTreeLeagueElement>;
  relatedLeagues: SportsTreeLeagueElement[];
  title: string;
  position: number;
  open: boolean;
  to?: RouteLocationRaw;
}

interface LeagueBreadcrumbsItemEmits {
  (e: 'toggle', id: BreadcrumbId): void;
}

defineProps<LeagueBreadcrumbsItemProps>();
const emit = defineEmits<LeagueBreadcrumbsItemEmits>();
</script>

<template>
  <VBreadcrumb v-auto-id="'LeagueBreadcrumbsItem'"
    :type="BreadcrumbType.MENU"
    :title="title"
    :position="position"
    :open="open"
    :to="to"
    :can-expand="relatedLeagues.length > 0"
    controlled
    @toggle="emit('toggle', BreadcrumbId.Leagues)"
  >
    <template #default="{ forceHide }">
      <VList :list-type="ListType.GROUP">
        <BreadcrumbsRelatedListItem
          v-for="relatedLeague in relatedLeagues"
          :key="`league-breadcrumb-${relatedLeague.league.id}`"
          :is-active="!!leagueElement && leagueElement.league.id === relatedLeague.league.id"
          :title="relatedLeague.league.name"
          :to="resolveLeaguePageLink(relatedLeague.league.navigationParameters)"
          @link-click="forceHide"
        />
      </VList>
    </template>
  </VBreadcrumb>
</template>
