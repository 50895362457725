import type { WatchStopHandle } from 'vue';
import type { RouteLocationNormalized, Router } from 'vue-router';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { isString } from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';

import type { SportlinePageIdentifier } from 'web/src/modules/sportline/types/navigation';
import { useSportlineRedirectInfoStore } from 'web/src/modules/sportline/submodules/redirects/store';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

import { useSportlineTreeElementForPageIdentifier } from './useSportlineTreeElementForPageIdentifier';

interface RedirectAllowedTargets {
  sport?: boolean;
  region?: boolean;
  league?: boolean;
  home?: boolean;
}

interface UseRedirectBySportsTreeProps {
  canShowRedirectedInfoTargets?: RedirectAllowedTargets;
  router?: AppVueRouter;
  code?: 301 | 302;
  allowedTargets?: RedirectAllowedTargets;
  redirectWasHandled?(state: boolean): void;
}

interface UseRedirectBySportsTreeComposable {
  make(): Promise<void>;
  redirect(): Promise<void>;
  clearTimeout(): void;
}

export function useRedirectBySportsTree(
  props: UseRedirectBySportsTreeProps,
): UseRedirectBySportsTreeComposable {
  const redirectCode = props.code ?? 301;
  const canShowRedirectedInfoTargets = props.canShowRedirectedInfoTargets ?? {};
  const allowedTargets = props.allowedTargets ?? {};
  const { redirectWasHandled } = props;

  const router = props.router ?? useRouter();
  const sportlineRedirectInfoStore = useSportlineRedirectInfoStore();

  let unwatch: Maybe<WatchStopHandle> = null;
  let timeout: Maybe<number> = null;

  const pageIdentifier = ref<SportlinePageIdentifier>({ sportFamily: '' });

  const {
    isLoaded,
    isLoadingErrorOccurred,
    sportElement,
    regionElement,
    leagueElement,
    initialRequests,
  } = useSportlineTreeElementForPageIdentifier({ pageIdentifier });

  const isReadyToRedirect = computed<boolean>(() => (isLoaded.value || isLoadingErrorOccurred.value));

  function makeRedirectToLeaguePage(): Optional<ReturnType<Router['push']>> {
    if (leagueElement.value && leagueElement.value.counters.total > 0
      && regionElement.value && regionElement.value.leagues.length > 0
      && sportElement.value && sportElement.value.leaguesCount > 0) {
      return router.pushRedirect({
        name: RouteName.SPORT_LEAGUE,
        params: {
          sportFamily: sportElement.value.sport.navigationParameters.urlName,
          regionUrlName: regionElement.value.region.navigationParameters.urlName,
          urlName: leagueElement.value.league.navigationParameters.urlName,
          id: leagueElement.value.league.id,
        },
      }, redirectCode);
    }

    return undefined;
  }

  function makeRedirectToRegionPage(): Optional<ReturnType<Router['push']>> {
    if (regionElement.value && regionElement.value.leagues.length > 0
      && sportElement.value && sportElement.value.leaguesCount > 0) {
      return router.pushRedirect({
        name: RouteName.SPORT_REGION,
        params: {
          sportFamily: sportElement.value.sport.navigationParameters.urlName,
          urlName: regionElement.value.region.navigationParameters.urlName,
          id: regionElement.value.region.id,
        },
      }, redirectCode);
    }

    return undefined;
  }

  function makeRedirectToSportPage(): Optional<ReturnType<Router['push']>> {
    if (sportElement.value && sportElement.value.leaguesCount > 0) {
      return router.pushRedirect({
        name: RouteName.SPORTLINE_PREMATCH_EVENTS,
        params: {
          family: sportElement.value.sport.navigationParameters.urlName,
        },
      }, redirectCode);
    }

    return undefined;
  }

  function makeRedirectToHomePage(): ReturnType<Router['push']> {
    return router.pushRedirect({
      name: RouteName.HOME,
    }, redirectCode);
  }

  function stopWatchingLoadedState(): void {
    unwatch?.();
    unwatch = null;
  }

  function clearTimeout(): void {
    if (timeout) {
      window.clearTimeout(timeout);
      timeout = null;
    }
  }

  function checkCanShowRedirectedInfo(from: RouteLocationNormalized['name'], key: keyof RedirectAllowedTargets): void {
    if (canShowRedirectedInfoTargets[key] && isString(from)) {
      sportlineRedirectInfoStore.showRedirectWasHandledInfo({ from });
    }
  }

  function redirect(): Promise<void> {
    stopWatchingLoadedState();
    clearTimeout();

    redirectWasHandled?.(true);

    const from = router.currentRoute.value.name;

    if (allowedTargets.league) {
      const redirectToPage = makeRedirectToLeaguePage();
      if (redirectToPage) {
        void redirectToPage.then(() => checkCanShowRedirectedInfo(from, 'league'));
        return Promise.resolve();
      }
    }

    if (allowedTargets.region) {
      const redirectToPage = makeRedirectToRegionPage();
      if (redirectToPage) {
        void redirectToPage.then(() => checkCanShowRedirectedInfo(from, 'region'));
        return Promise.resolve();
      }
    }

    if (allowedTargets.sport) {
      const redirectToPage = makeRedirectToSportPage();
      if (redirectToPage) {
        void redirectToPage.then(() => checkCanShowRedirectedInfo(from, 'sport'));
        return Promise.resolve();
      }
    }

    void makeRedirectToHomePage().then(() => checkCanShowRedirectedInfo(from, 'home'));
    return Promise.resolve();
  }

  function make(): Promise<void> {
    return new Promise((resolve) => {
      pageIdentifier.value = getSportlinePageNavigationParameters(router.currentRoute.value);

      unwatch = watch(isReadyToRedirect, (value) => {
        if (value) {
          void redirect().finally(resolve);
        }
      }, { immediate: true });

      void initialRequests();

      timeout = window.setTimeout(() => {
        stopWatchingLoadedState();
        void redirect().finally(resolve);
      }, 10_000);
    });
  }

  return {
    make,
    redirect,
    clearTimeout,
  };
}
