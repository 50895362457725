import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportFilterKickoffPeriod, SportlineType } from 'web/src/modules/sportline/enums';
import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';

interface UseSportsListDateFilterProps {
  dateTimeFilter: Ref<SportDateTimeFilter>;
}

export interface UseSportsListDateFilterComposable {
  dateTimeFilterCustomFrom: Ref<Optional<number>>;
  dateTimeFilterCustomTo: Ref<Optional<number>>;
  dateTimeFilterActiveKickoffPeriod: Ref<SportFilterKickoffPeriod>;
  dateTimeFilterActiveSportlineType: Ref<Optional<SportlineType>>;
}

export function useSportsListDateFilter(
  props: UseSportsListDateFilterProps,
): UseSportsListDateFilterComposable {
  const { dateTimeFilter } = props;

  const dateTimeFilterCustomFrom = computed<Optional<number>>(
    () => dateTimeFilter.value.customDateFrom ?? undefined,
  );
  const dateTimeFilterCustomTo = computed<Optional<number>>(
    () => dateTimeFilter.value.customDateTo ?? undefined,
  );
  const dateTimeFilterActiveKickoffPeriod = computed<SportFilterKickoffPeriod>(
    () => dateTimeFilter.value.activeKickoffPeriod,
  );
  const dateTimeFilterActiveSportlineType = computed<Optional<SportlineType>>(
    () => dateTimeFilter.value.sportlineType as Optional<SportlineType>,
  );

  return {
    dateTimeFilterCustomFrom,
    dateTimeFilterCustomTo,
    dateTimeFilterActiveKickoffPeriod,
    dateTimeFilterActiveSportlineType,
  };
}
