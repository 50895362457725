<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { StatisticChartBarType } from 'web/src/modules/sportline/submodules/core-statistic/enums';

import { useChartBarWidth } from './composables/useChartBarWidth';

interface StatisticChartBarLabeledProps {
  type?: StatisticChartBarType;
  toFixed?: Maybe<number>;
  homePercentage?: number;
  drawPercentage?: number;
  awayPercentage?: number;
}

const props = withDefaults(defineProps<StatisticChartBarLabeledProps>(), {
  type: StatisticChartBarType.NARROW,
});

const isLabeledType = computed<boolean>(() => (props.type === StatisticChartBarType.LABELED));
const isNarrowType = computed<boolean>(() => (props.type === StatisticChartBarType.NARROW));
const {
  homeChartWidth,
  drawsChartWidth,
  awayChartWidth,
  homePercentageLabel,
  drawPercentageLabel,
  awayPercentageLabel,
} = useChartBarWidth({
  toFixed: toRef(props, 'toFixed', null),
  homePercentage: toRef(props, 'homePercentage', 0),
  drawPercentage: toRef(props, 'drawPercentage', 0),
  awayPercentage: toRef(props, 'awayPercentage', 0),
});
</script>

<template>
  <div v-auto-id="'StatisticChartBar'"
    :class="{
      [$style['statistic-chart-bar__container']]: true,
      [$style['statistic-chart-bar__container--labeled']]: isLabeledType,
      [$style['statistic-chart-bar__container--narrow']]: isNarrowType,
    }"
  >
    <div
      v-if="homeChartWidth > 0"
      :class="[
        $style['statistic-chart-bar'],
        $style['statistic-chart-bar--host'],
      ]"
      :style="{ width: `${homeChartWidth}%` }"
    >
      <template v-if="isLabeledType && homePercentageLabel">
        {{ homePercentageLabel }}
      </template>
    </div>

    <div
      v-if="drawsChartWidth > 0"
      :class="[
        $style['statistic-chart-bar'],
        $style['statistic-chart-bar--draws'],
      ]"
      :style="{ width: `${drawsChartWidth}%` }"
    >
      <template v-if="isLabeledType && drawPercentageLabel">
        {{ drawPercentageLabel }}
      </template>
    </div>

    <div
      v-if="awayChartWidth > 0"
      :class="[
        $style['statistic-chart-bar'],
        $style['statistic-chart-bar--guest'],
      ]"
      :style="{ width: `${awayChartWidth}%` }"
    >
      <template v-if="isLabeledType && awayPercentageLabel">
        {{ awayPercentageLabel }}
      </template>
    </div>
  </div>
</template>

<style lang="scss" module>
.statistic-chart-bar {
  @include medium\10\12;

  height: 100%;
  padding: 0 4px;
  border-radius: 2px;

  &__container {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    width: 100%;

    &--labeled {
      height: 12px;
    }

    &--narrow {
      height: 4px;
    }
  }

  &--host {
    color: var(--BrandText);
    background: var(--StatsA);
  }

  &--draws {
    color: var(--BrandText);
    background: var(--StatsDraw);
  }

  &--guest {
    color: var(--BrandText);
    background: var(--StatsB);
  }
}
</style>
