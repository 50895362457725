import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type {
  DateTimeRange,
} from 'web/src/modules/sportline/types';
import type {
  BuildSportOptions,
  CoreSportlineFetchOptions,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import type { SportSegmentSettings } from 'web/src/modules/sportline/types/settings';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useSportlineEventsChangesListeners } from 'web/src/modules/sportline/store/composables';
import { normalizeComingResponse } from 'web/src/modules/sportline/submodules/segment/store/utils';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';

interface SegmentStoreComingProps {
  comingUpdateInterval: Ref<number>;
  parseSportlineSettings: Ref<BuildSportOptions>;
  sportSegmentsSettings: Ref<SportSegmentSettings>;
  doUseRelativeTimeForSportline: Ref<boolean>;
  comingEventsKickoffPeriod: Ref<DateTimeRange>;
}

interface SegmentStoreComingComposable {
  rawComingEventsResponse: Ref<Maybe<Readonly<GetSportEventsResponse>>>;
  totalCount: Ref<number>;
  initialRequests(): Promise<void>;
  onInit(): void;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
  syncBackgroundRequests(options: { force?: boolean; silent?: boolean }): Promise<void>;
}

export default function useSegmentStoreComingComposable(
  props: SegmentStoreComingProps,
): SegmentStoreComingComposable {
  const { comingUpdateInterval } = props;

  const apiService = useSportlineApiService();
  const broadcastSelected = useBroadcastSelected();

  const rawComingEventsResponse = ref<Maybe<Readonly<GetSportEventsResponse>>>(null);
  const lastUpdate = new BackgroundUpdateStopwatch<['segmentComingEvents']>({
    segmentComingEvents: async ({ silent }: CoreSportlineFetchOptions) => {
      const response = await apiService.loadComingEvents({
        filters: props.comingEventsKickoffPeriod.value,
        vTag: rawComingEventsResponse.value?.vtag,
        sportFamily: props.sportSegmentsSettings.value.sportFamily,
        silent,
      });
      rawComingEventsResponse.value = normalizeComingResponse({ response });
      broadcastSelected.updateDataInStorageByResponse({ response });
      lastUpdate.update('segmentComingEvents');
    },
  });

  const totalCount = computed<number>(() => rawComingEventsResponse.value?.totalCount || 0);

  const {
    initialRequests,
    syncState,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval: comingUpdateInterval,
  });
  const {
    onInit: sportlineEventsChangesListenersOnInit,
  } = useSportlineEventsChangesListeners({
    setResponse(response: GetSportEventsResponse): void {
      rawComingEventsResponse.value = response;
    },
    getResponse(): Maybe<GetSportEventsResponse> {
      return rawComingEventsResponse.value;
    },
  });

  function onInit(): void {
    sportlineEventsChangesListenersOnInit();
    useSyncSportline(async (silent: boolean): Promise<void> => {
      await syncState({ silent });
    }, comingUpdateInterval);
  }

  return {
    rawComingEventsResponse,
    totalCount,
    initialRequests,
    onInit,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  };
}
