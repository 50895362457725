<script lang="ts" setup>
import type { Component } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { computed, toRef } from 'vue';

import type { IconNameType } from '@leon-hub/icons';
import { IconName, IconSize } from '@leon-hub/icons';

import { VLinkPrevented, VRouterLink } from '@components/link';
import { VIcon } from '@components/v-icon';

import { BreadcrumbType } from 'web/src/components/Breadcrumbs/VBreadcrumb/enums';

interface VCrumbProps {
  title?: string;
  to?: Maybe<RouteLocationRaw>;
  isPrevented?: boolean;
  isOpen?: boolean;
  iconName?: Maybe<IconNameType>;
  canExpand?: boolean;
  type?: BreadcrumbType;
}

interface VCrumbEmits {
  (e: 'toggle'): void;
}

interface VCrumbSlots {
  icon?(props: Dictionary<never>): void;
}

const props = defineProps<VCrumbProps>();
const emit = defineEmits<VCrumbEmits>();
const slots = defineSlots<VCrumbSlots>();

const to = toRef(props, 'to', null);
const isPrevented = toRef(props, 'isPrevented', false);
const iconName = toRef(props, 'iconName', null);

const tag = computed<string | Component>(() => {
  if (!to.value) {
    return 'div';
  }
  return isPrevented.value ? VLinkPrevented : VRouterLink;
});
const properties = computed<Record<string, unknown>>(() => (to.value ? { to: to.value } : {}));
const hasIcon = computed<boolean>(() => !!iconName.value || !!slots.icon);
</script>

<template>
  <component v-auto-id="'VCrumb'"
    v-bind="properties"
    :is="tag"
    :title="title"
    :class="{
      [$style.crumb]: true,
      [$style['crumb--menu']]: type === BreadcrumbType.MENU,
      [$style['crumb--open']]: isOpen,
      [$style['crumb--can-interact']]: canExpand || to,
      [$style['crumb--with-indicator']]: canExpand,
      [$style['crumb--with-icon']]: hasIcon,
    }"
    @click="emit('toggle')"
  >
    <div
      v-if="hasIcon"
      :class="$style.crumb__icon"
    >
      <VIcon
        v-if="iconName"
        :name="iconName"
        :size="IconSize.SIZE_16"
      />
      <template v-else>
        <slot name="icon" />
      </template>
    </div>

    <div :class="$style.crumb__title">
      {{ title }}
    </div>

    <div
      v-if="canExpand"
      :class="$style.crumb__indicator"
    >
      <VIcon
        :name="isOpen ? IconName.EXPAND_UP : IconName.EXPAND_DOWN"
        :size="IconSize.SIZE_16"
      />
    </div>
  </component>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .crumb {
    $self: '.crumb';

    display: flex;
    gap: 4px;
    align-items: center;
    min-height: 40px;
    padding-right: 12px;
    padding-left: 12px;
    color: var(--TextPrimary);
    text-decoration: none;
    user-select: none;
    background-color: var(--Layer1);
    border-radius: 5px;

    &--with-indicator {
      padding-right: 8px;
    }

    &--with-icon {
      padding-left: 8px;
    }

    &--can-interact {
      cursor: pointer;

      @include for-hover {
        &:not(#{$self}--open):hover {
          color: var(--TextDefault);
          background-color: var(--Highlight);
        }
      }
    }

    &--open {
      color: var(--Layer1);
      background-color: var(--TextDefault);

      &:after {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        height: 4px;
        content: '';
        background-color: var(--TextDefault);
      }

      &#{$self}--menu {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &__title {
      @include regular\13\16;

      max-width: 158px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
    }

    &__indicator {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
