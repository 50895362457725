import { isObject } from '@leon-hub/guards';

import { useErrorsConverter } from '@core/errors';

import type {
  BetlineGetLeagueStatisticResponse,
  BetlineLeagueTopPlayers,
} from 'web/src/modules/sportline/types/rest';
import { SportlineRestErrorCode } from 'web/src/modules/sportline/errors/rest';

export * from './PostMatchStatistics';

export function isStatisticNotFoundError(error_: unknown): boolean {
  const error = useErrorsConverter().convertToBaseError(error_);
  return error.code.equals(SportlineRestErrorCode.MISSING_EVENT_STATISTIC);
}

export function isLeagueMatchesStatisticEmpty(
  value?: Maybe<BetlineGetLeagueStatisticResponse>,
): value is null | undefined {
  if (!value) {
    return true;
  }
  return Object.keys(value)
    .every((key: keyof typeof value) => {
      const data = value[key];
      return !isObject(data) || !Object.keys(data).length;
    });
}

export function isTopPlayersEmpty(value?: Maybe<BetlineLeagueTopPlayers>): value is null | undefined {
  if (!value) {
    return true;
  }
  return value.scorers.length === 0
    && value.assists.length === 0;
}
