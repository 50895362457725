import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import type { UseIsFavoriteTabAvailable } from 'web/src/modules/sportline/submodules/favorites/types';
import type { SportEventsSegmentSelectedFilter } from 'web/src/modules/sportline/submodules/segment/types';
import type {
  DateTimeRange,
  LiveSportlineEvent,
  PrematchSportlineEvent,
  RegionElement,
  SportElement,
  SportEventsFilterTransitionDuration,
} from 'web/src/modules/sportline/types';
import type {
  BuildSportOptions,
  GetSportEventsResponse,
  GetSportsResponse,
  SportsTreeSportElement,
} from 'web/src/modules/sportline/types/rest';
import type { SportSegmentSettings } from 'web/src/modules/sportline/types/settings';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { useSportSegmentsSettings } from 'web/src/modules/sportline/store/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { KickoffPeriodUtils } from 'web/src/modules/sportline/utils';

import useSegmentStoreComingComposable from './useSegmentStoreComingComposable';
import useSegmentStoreFavoritesComposable from './useSegmentStoreFavoritesComposable';
import { useSegmentStoreFilterComposable } from './useSegmentStoreFilterComposable';
import useSegmentStoreLiveComposable from './useSegmentStoreLiveComposable';
import { useSegmentStoreResponseMapping } from './useSegmentStoreResponseMapping';

interface SegmentStoreProps {
  sportSegmentsSettingsId: string;
  backgroundFavoritesId: CustomerFavoritesIdsBackgroundUpdateKey;
  filterFavoritesEventsListBySegmentId?: string;
  filterFavoritesEventsListBySportlineType?: SportlineType;
  allLiveTabUpdateInterval: Ref<number>;
  selectedLiveTabUpdateInterval: Ref<number>;
  comingUpdateInterval: Ref<number>;
}

interface SegmentStoreComposable extends UseIsFavoriteTabAvailable {
  isPageEnabled: Ref<boolean>;
  isReady: Ref<boolean>;
  filterIsReady: Ref<boolean>;
  totalCount: Ref<number>;
  isMarketTypesSelectionEnabled: Ref<boolean>;
  isFilterTransitionFadeEffectEnabled: Ref<boolean>;
  activeSportElement: Ref<Maybe<SportElement>>;
  activeRegionElement: Ref<Maybe<RegionElement>>;
  filterTransitionDuration: Ref<SportEventsFilterTransitionDuration>;
  selectedFilter: Ref<SportEventsSegmentSelectedFilter>;
  allowedSportFamily: Ref<string[]>;
  parseSportlineSettings: Ref<BuildSportOptions>;
  sportSegmentsSettings: Ref<SportSegmentSettings>;
  fullEventsResponse: Ref<Maybe<GetSportEventsResponse>>;
  fullSportsList: Ref<SportsTreeSportElement[]>;
  comingResponseWithoutLive: Ref<Maybe<GetSportEventsResponse>>;
  hasFavoritesOptimistic: Ref<boolean>;
  favoriteSelectedRegionFamily: Ref<Maybe<string>>;
  favoriteEventsFullList: Ref<SportElement[]>;
  favoriteEventsListForDisplay: Ref<SportElement[]>;
  customerOptimisticFavoriteSportEventsIds: Ref<readonly string[]>;
  customerOptimisticFavoriteLeaguesIds: Ref<readonly string[]>;
  liveEventsSportsMap: Ref<Map<string, SportElement<LiveSportlineEvent>>>;
  comingEventsSportsMap: Ref<Map<string, SportElement<PrematchSportlineEvent>>>;
  compiledComingSports: Ref<Maybe<SportElement<PrematchSportlineEvent>[]>>;
  liveDisciplinesMap: Ref<Record<string, RegionElement>>;
  comingDisciplinesMap: Ref<Record<string, RegionElement>>;
  compiledComingDisciplinesList: Ref<Maybe<RegionElement[]>>;
  setSelectedFilter(filter?: SportEventsSegmentSelectedFilter): void;
  initialRequests(): Promise<void>;
  syncBackgroundRequests(): void;
  setBackgroundUpdateEnabled(value: boolean): void;
  setFavoriteSelectedRegionFamily(regionFamily: Maybe<string>): void;

  // @TODO remove export raw data after remove vuex store deps
  rawLiveEventsResponse: Ref<Maybe<Readonly<GetSportEventsResponse>>>;
  rawComingEventsResponse: Ref<Maybe<Readonly<GetSportEventsResponse>>>;
  rawFullSportsListResponse: Ref<Maybe<Readonly<GetSportsResponse>>>;
}

export function useSegmentStoreComposable(
  props: SegmentStoreProps,
): SegmentStoreComposable {
  const {
    sportSegmentsSettingsId,
    allLiveTabUpdateInterval,
    selectedLiveTabUpdateInterval,
    comingUpdateInterval,
  } = props;

  const settingsStore = useSportlineSettingsStore();
  const {
    onInit: globalSportlineEventsOnInit,
  } = useGlobalSportlineEvents();

  const sportSegmentsSettings = useSportSegmentsSettings(sportSegmentsSettingsId);

  const isMarketTypesSelectionEnabled = toRef(settingsStore, 'isMarketTypesSelectionEnabled');
  const isFilterTransitionFadeEffectEnabled = computed(
    () => settingsStore.isFilterTransitionFadeEffectEnabled,
  );
  const filterTransitionDuration = computed(() => settingsStore.filterTransitionDuration);
  const parseSportlineSettings: Ref<BuildSportOptions> = toRef(settingsStore, 'parseSportlineSettings');
  const allowedSportFamily: Ref<string[]> = toRef(settingsStore, 'allowedSportFamily');
  const doUseRelativeTimeForSportline: Ref<boolean> = toRef(settingsStore, 'doUseRelativeTimeForSportline');
  const isPageEnabled = computed<boolean>(() => sportSegmentsSettings.value.isPageEnabled);
  const comingEventsKickoffPeriod = computed<DateTimeRange>(() => {
    const comingEventsKickoffFilter = sportSegmentsSettings.value.comingKickoffFilter;
    return doUseRelativeTimeForSportline.value
      ? KickoffPeriodUtils.optionalRelativeDateTimeRangeInMinutes(comingEventsKickoffFilter)
      : KickoffPeriodUtils.dateTimeRangeFromKickoffPeriod(comingEventsKickoffFilter);
  });

  const {
    isReady: filterIsReady,
    selectedFilter,
    fullSportsList,
    rawFullSportsListResponse,
    filterOptionsIds,
    activeSportElement,
    activeRegionElement,
    setSelectedFilter,
    initialRequests: filtersInitialRequests,
    onInit: filtersOnInit,
    syncBackgroundRequests: filtersSyncBackgroundRequests,
    setBackgroundUpdateEnabled: filtersSetBackgroundUpdateEnabled,
  } = useSegmentStoreFilterComposable({
    allLiveTabUpdateInterval,
    selectedLiveTabUpdateInterval,
    parseSportlineSettings,
    sportSegmentsSettings,
    comingEventsKickoffPeriod,
  });
  const {
    isReady: liveIsReady,
    rawLiveEventsResponse,
    onInit: liveOnInit,
    initialRequests: liveInitialRequests,
    syncBackgroundRequests: liveSyncBackgroundRequests,
    setBackgroundUpdateEnabled: liveSetBackgroundUpdateEnabled,
  } = useSegmentStoreLiveComposable({
    allLiveTabUpdateInterval,
    selectedLiveTabUpdateInterval,
    sportSegmentsSettings,
    filterOptionsIds,
    activeSportElement,
    activeRegionElement,
  });
  const {
    rawComingEventsResponse,
    onInit: comingOnInit,
    initialRequests: comingInitialRequests,
    syncBackgroundRequests: comingSyncBackgroundRequests,
    setBackgroundUpdateEnabled: comingSetBackgroundUpdateEnabled,
  } = useSegmentStoreComingComposable({
    comingUpdateInterval,
    parseSportlineSettings,
    sportSegmentsSettings,
    doUseRelativeTimeForSportline,
    comingEventsKickoffPeriod,
  });
  const {
    comingResponseWithoutLive,
    fullEventsResponse,
    liveEventsSportsMap,
    comingEventsSportsMap,
    compiledComingSports,
    liveDisciplinesMap,
    comingDisciplinesMap,
    compiledComingDisciplinesList,
    totalCount,
  } = useSegmentStoreResponseMapping({
    rawLiveEventsResponse,
    rawComingEventsResponse,
    parseSportlineSettings,
    sportSegmentsSettings,
  });

  const isReady = computed(() => liveIsReady.value && filterIsReady.value);

  const {
    hasFavoritesOptimistic,
    isFavoritesTabAvailable,
    favoriteSelectedRegionFamily,
    favoriteEventsFullList,
    favoriteEventsListForDisplay,
    customerOptimisticFavoriteSportEventsIds,
    customerOptimisticFavoriteLeaguesIds,
    onInit: onFavoriteInit,
    initialRequests: initialFavoriteRequests,
    setFavoriteSelectedRegionFamily,
    setBackgroundUpdateEnabled: setFavoritesBackgroundUpdateEnabled,
  } = useSegmentStoreFavoritesComposable({
    filterEventsListBySegmentId: props.filterFavoritesEventsListBySegmentId,
    backgroundFavoritesId: props.backgroundFavoritesId,
    filterEventsListBySportlineType: props.filterFavoritesEventsListBySportlineType,
    eventsResponseForOptimisticCheck: computed(() => {
      if (props.filterFavoritesEventsListBySportlineType === SportlineType.Live) {
        return rawLiveEventsResponse.value;
      }

      if (props.filterFavoritesEventsListBySportlineType === SportlineType.Prematch) {
        return comingResponseWithoutLive.value;
      }

      return fullEventsResponse.value;
    }),
  });

  async function initialRequests(): Promise<void> {
    const coreRequests = Promise.allSettled([
      liveInitialRequests(),
      comingInitialRequests(),
      filtersInitialRequests(),
    ]);
    // do not await favorites
    void initialFavoriteRequests();

    await coreRequests;
  }

  function syncBackgroundRequests(): void {
    void liveSyncBackgroundRequests({ silent: true });
    void comingSyncBackgroundRequests({ silent: true });
    void filtersSyncBackgroundRequests({ silent: true });
  }

  function setBackgroundUpdateEnabled(value: boolean): void {
    void liveSetBackgroundUpdateEnabled(value);
    void comingSetBackgroundUpdateEnabled(value);
    void filtersSetBackgroundUpdateEnabled(value);
    void setFavoritesBackgroundUpdateEnabled(value);
  }

  // on init
  globalSportlineEventsOnInit();
  liveOnInit();
  comingOnInit();
  filtersOnInit();
  onFavoriteInit();

  return {
    isPageEnabled,
    isReady,
    filterIsReady,
    totalCount,
    isMarketTypesSelectionEnabled,
    isFilterTransitionFadeEffectEnabled,
    filterTransitionDuration,
    activeSportElement,
    activeRegionElement,
    selectedFilter,
    allowedSportFamily,
    rawLiveEventsResponse,
    rawComingEventsResponse,
    rawFullSportsListResponse,
    fullEventsResponse,
    fullSportsList,
    comingResponseWithoutLive,
    parseSportlineSettings,
    sportSegmentsSettings,
    hasFavoritesOptimistic,
    isFavoritesTabAvailable,
    favoriteSelectedRegionFamily,
    favoriteEventsFullList,
    favoriteEventsListForDisplay,
    customerOptimisticFavoriteSportEventsIds,
    customerOptimisticFavoriteLeaguesIds,
    liveEventsSportsMap,
    comingEventsSportsMap,
    compiledComingSports,
    liveDisciplinesMap,
    comingDisciplinesMap,
    compiledComingDisciplinesList,
    setSelectedFilter,
    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
    setFavoriteSelectedRegionFamily,
  };
}
