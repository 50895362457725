<script lang="ts" setup>
/**
 * The main region component with leagues components.
 * Seems it could be removed.
 */
import { toRef } from 'vue';

import type { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event';
import type { RegionElement, Sport } from 'web/src/modules/sportline/types';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';
import type { UpcomingLabelType } from 'web/src/modules/sportline/views/EventBlock/enums';
import { useSportlineRegionLeagueElements } from 'web/src/modules/sportline/composables/list-components/region';
import SportlineLeagueBlock from 'web/src/modules/sportline/views/LeagueBlock/SportlineLeagueBlock.vue';

interface SportlineRegionBlockProps {
  sport: Sport;
  regionElement: RegionElement;
  upcomingLabelType?: Maybe<UpcomingLabelType>;
  isHideLeagueInfo?: boolean;
  isColumnNamesDisplayDisabled?: boolean;
  isShowAddLeagueToFavorite?: boolean;
  isDisplayMainEventsDisabled?: boolean;
  isDisplayOutrightEventsEnabled?: boolean;
  isDisplayOutrightHeadlineDisabled?: boolean;
  canAddToFavorite?: boolean;
  canAddOutrightsToFavorite?: boolean;
  isExtendedEventViewDisabled?: boolean;
  collapseLeagueKey?: Maybe<string>;
  isLeagueDefaultExpanded?: boolean;
  isFilterActive?: boolean;
  activeEventId?: Maybe<string>;
  extendedBlockType?: ExtendedEventBlock;
  basisKey: SportlineFragmentBasisKey;
}

type SportlineRegionBlockEmits = (e: 'click-event') => void;

const props = defineProps<SportlineRegionBlockProps>();
const emit = defineEmits<SportlineRegionBlockEmits>();

const isDisplayOutrightEventsEnabled = toRef(props, 'isDisplayOutrightEventsEnabled');
const regionElement = toRef(props, 'regionElement');

const {
  leagueElements,
} = useSportlineRegionLeagueElements({
  isDisplayOutrightEventsEnabled,
  regionElement,
});
</script>

<template>
  <div v-auto-id="'SportlineRegionBlock'">
    <SportlineLeagueBlock
      v-for="leagueElement in leagueElements"
      :key="leagueElement.key"
      :sport="sport"
      :region="regionElement.region"
      :league-element="leagueElement"
      :upcoming-label-type="upcomingLabelType"
      :is-hide-league-info="isHideLeagueInfo"
      :is-column-names-display-disabled="isColumnNamesDisplayDisabled"
      :is-show-add-league-to-favorite="isShowAddLeagueToFavorite"
      :is-display-main-events-disabled="isDisplayMainEventsDisabled"
      :is-display-outright-events-enabled="isDisplayOutrightEventsEnabled"
      :is-display-outright-headline-disabled="isDisplayOutrightHeadlineDisabled"
      :can-add-to-favorite="canAddToFavorite"
      :can-add-outrights-to-favorite="canAddOutrightsToFavorite"
      :is-extended-event-view-disabled="isExtendedEventViewDisabled"
      :active-event-id="activeEventId"
      :expand-key="collapseLeagueKey ? `${collapseLeagueKey}-${leagueElement.league.id}` : undefined"
      :is-default-expanded="isLeagueDefaultExpanded"
      :is-filter-active="isFilterActive"
      :extended-block-type="extendedBlockType"
      :basis-key="basisKey"
      @click-event="emit('click-event')"
    />
  </div>
</template>
