import type { Web2Image } from '@leon-hub/api-sdk';

import type {
  BetlineCountryData,
  BetlineLeagueData,
  BetlineLeagueRepresentationData,
} from 'web/src/modules/sportline/submodules/core-statistic/types';
import type { BetlineGetLeagueDataResponse } from 'web/src/modules/sportline/types/rest';
import { getSportFamilyBackground } from 'web/src/modules/sportline/utils';

export function parseBetlineLeagueCountryDataResponse(
  response?: Maybe<BetlineGetLeagueDataResponse['country']>,
): Maybe<BetlineCountryData> {
  if (!response) { return null; }
  const { logo, name, iso } = response;
  if (!name || !iso) { return null; }
  return { logo: logo || null, name, iso };
}

export function parseBetlineLeagueBackgroundData(
  response?: Maybe<BetlineGetLeagueDataResponse['background']>,
): Maybe<Web2Image> {
  if (!response) { return null; }
  const { src } = response;
  if (!src?.src) { return null; }
  return { ...src };
}

function isBetlineLeagueDataEmpty(data: BetlineLeagueData): boolean {
  const {
    id,
    ...fieldsDefineEmptiness
  } = data;

  return Object.values(fieldsDefineEmptiness).every((field) => !field);
}

export function parseBetlineLeagueDataResponse(
  response?: Maybe<BetlineGetLeagueDataResponse>,
): Maybe<BetlineLeagueData> {
  if (!response) { return null; }

  const {
    id,
    country,
    currentSeasonStart,
    currentSeasonEnd,
    currentSeasonYear,
  } = response;

  const betlineLeagueData: BetlineLeagueData = {
    id: id ? String(id) : null,
    country: parseBetlineLeagueCountryDataResponse(country),
    currentSeasonStart: currentSeasonStart || null,
    currentSeasonEnd: currentSeasonEnd || null,
    currentSeasonYear: currentSeasonYear || null,
  };

  return isBetlineLeagueDataEmpty(betlineLeagueData) ? null : betlineLeagueData;
}

export function parseBetlineLeagueRepresentationDataResponse(
  response?: Maybe<BetlineGetLeagueDataResponse>,
): Maybe<BetlineLeagueRepresentationData> {
  if (!response) { return null; }

  const {
    logoUrl,
    family,
    background: backgroundImageSrc,
  } = response;

  return {
    icon: family?.toLowerCase() || null,
    logoUrl: logoUrl || null,
    backgroundImage: parseBetlineLeagueBackgroundData(backgroundImageSrc),
    background: getSportFamilyBackground(family),
  };
}
