export * from './StatisticChartBar';
export * from './StatisticColoredLabel';

export enum TimelineMarkType {
  HALF_TIME = 1,
  FULL_TIME = 2,
  HALF_EXTRA_TIME = 3,
  FULL_EXTRA_TIME = 4,
  PENALTY_SHOOTOUT = 5,
  INCIDENTS = 6,
}

export enum IncidentType {
  GOAL = 1,
  OWN_GOAL,
  DISABLED_GOAL,
  CARD_YELLOW,
  CARD_RED,
  SUBSTITUTION,
}

export enum TimelineMarkCompetitorType {
  HOST = 1,
  GUEST = 2,
}

export enum GoalType {
  HEADER = 'Header',
  PENALTY = 'Penalty',
  OWN_GOAL = 'Own goal',
  EMPTY = '0',
}
