<script lang="ts" setup>
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';

import SidebarCaption from 'web/src/components/SidebarMenu/SidebarCaption/SidebarCaption.vue';
import TheSidebarMenu from 'web/src/components/SidebarMenu/TheSidebarMenu/TheSidebarMenu.vue';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import DateTimeFilter from 'web/src/modules/sportline/components/date-time-filter/DateTimeFilter.vue';
import { DateTimeFilterTabSet } from 'web/src/modules/sportline/enums';

import SportEventSidebarTopLeagues from '../components/top-leagues/SportsSidebarTopLeagues.vue';
import { useSidebarComponent } from '../composables';

const dateTimeFilterTabSet = DateTimeFilterTabSet.Default;
const dateTimeTabsType = TabsType.SMALL;

const {
  fullSportsList,
  fullFavoriteLeagues,
  isSportFiltersEnabled,
  maxTopLeaguesInTab,
  maxTopLeaguesTabsInSidebar,
  topLeaguesDefaultSportId,

  datetimeActiveKickoffPeriod,
  datetimeActiveSportlineType,
  datetimeCustomFrom,
  datetimeCustomTo,
  menu,

  onDateTimeFilterSelect,
  setDateTimeFilterCustomRange,
  setBackgroundUpdateEnabled,
  catalogClick,
  beforeSidebarNavigate,
} = useSidebarComponent();

onComponentActivated(() => { void setBackgroundUpdateEnabled(true); });
onComponentDeactivated(() => { void setBackgroundUpdateEnabled(false); });
</script>

<template>
  <TheSidebarMenu v-auto-id="'SportsSidebarRouteComponent'"
    :menu="menu"
    @level-click="catalogClick"
    @before-navigate="beforeSidebarNavigate"
  >
    <template #top>
      <SportEventSidebarTopLeagues
        :default-active-sport-id="topLeaguesDefaultSportId"
        :sports="fullSportsList"
        :favorites-sports="fullFavoriteLeagues"
        :max-in-list="maxTopLeaguesInTab"
        :max-tabs-count="maxTopLeaguesTabsInSidebar"
        @level-click="catalogClick"
      />
      <SidebarCaption :title="$t('JSPBET_SPORT_TYPES')" />

      <div :class="$style['sports-sidebar__tabs']">
        <DateTimeFilter
          v-if="isSportFiltersEnabled"
          :class="$style['sports-sidebar__date-time-filter']"
          :active-kickoff-period="datetimeActiveKickoffPeriod"
          :active-sportline-type="datetimeActiveSportlineType"
          :selected-range-from="datetimeCustomFrom"
          :selected-range-to="datetimeCustomTo"
          :tab-set="dateTimeFilterTabSet"
          :tabs-type="dateTimeTabsType"
          @select-filter="onDateTimeFilterSelect"
          @select-range="setDateTimeFilterCustomRange"
        />
      </div>
    </template>
  </TheSidebarMenu>
</template>

<style module lang="scss">
.sports-sidebar {
  &__date-time-filter {
    @include z-index(sidebar-menu);

    padding-left: 4px;
    margin: 4px 0;
  }
}
</style>
