<script setup lang="ts">
import { onBeforeMount, toRef } from 'vue';

import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';
import { IconName, IconSize } from '@leon-hub/icons';

import { ButtonKind, VButton } from '@components/buttons';
import { VIcon } from '@components/v-icon';

import type { SportlineType } from 'web/src/modules/sportline/enums';
import VDatepicker from 'web/src/components/Datepicker/VDatepicker/VDatepicker.vue';
import { DateInput } from 'web/src/components/Input';
import { DateInputCustomDate, DateInputRoundDateTo } from 'web/src/components/Input/enums';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import { DateTimeFilterTabSet, FocusedInput } from 'web/src/modules/sportline/enums';

import type { DateTimeFilterEmits } from './composables';
import type { DateTimeFilterReference } from './types';
import {
  useDateTimeFilter,
  useDateTimeFilterTabsSet,
} from './composables';

interface DateTimeFilterProps {
  activeKickoffPeriod?: SportFilterKickoffPeriod;
  activeSportlineType?: SportlineType;
  tabSet?: DateTimeFilterTabSet;
  selectedRangeFrom?: Optional<number>;
  selectedRangeTo?: Optional<number>;
  focused?: FocusedInput;
  tabsType?: TabsType;
  doRemovePaddings?: boolean;
}

const props = defineProps<DateTimeFilterProps>();
const emit = defineEmits<DateTimeFilterEmits>();

const activeKickoffPeriod = toRef(props, 'activeKickoffPeriod', SportFilterKickoffPeriod.ALL);
const activeSportlineType = toRef(props, 'activeSportlineType');
const tabSet = toRef(props, 'tabSet', DateTimeFilterTabSet.Full);
const focused = toRef(props, 'focused', FocusedInput.NONE);
const tabsType = toRef(props, 'tabsType', TabsType.NORMAL);
const selectedRangeFrom = toRef(props, 'selectedRangeFrom');
const selectedRangeTo = toRef(props, 'selectedRangeTo');

const {
  tabsRef,
  showSelectCustomRangeForm,
  focusedLocal,
  preparedForActivateTab,
  slideToActive,
  setFocused,
  onActivateTabClick,
  hideSelectCustomRange,
  rangeFromChanged,
  rangeToChanged,
} = useDateTimeFilter({
  activeKickoffPeriod,
  activeSportlineType,
  selectedRangeFrom,
  selectedRangeTo,
}, emit);
const { tabs } = useDateTimeFilterTabsSet({ tabSet });

defineExpose<DateTimeFilterReference>({ slideToActive });

onBeforeMount(() => {
  setFocused(focused.value);
});
</script>

<template>
  <div v-auto-id="'DateTimeFilter'"
    :class="$style['date-time-filter']"
  >
    <VTabs
      v-show="!showSelectCustomRangeForm"
      ref="tabsRef"
      :items="tabs"
      :active-id="preparedForActivateTab"
      :type="tabsType"
      time-filter
      is-bordered
      :swiper-wrapper-class="doRemovePaddings ? [] : undefined"
      @tab-click="onActivateTabClick"
    />
    <div
      v-show="showSelectCustomRangeForm"
      :class="$style['custom-range-select']"
    >
      <div :class="$style['custom-range-select__prefix']">
        <VIcon
          :name="IconName.DATE_RANGE"
          :size="IconSize.SIZE_16"
        />
      </div>

      <div
        v-if="process.env.VUE_APP_BROWSER_SAFARI && '1'"
        :class="$style['custom-range-select__content']"
      >
        <VDatepicker
          :value="selectedRangeFrom"
          :min="DateInputCustomDate.Now"
          :round-to="DateInputRoundDateTo.DayStart"
          clearable
          @change="rangeFromChanged"
        />

        <span :class="$style['custom-range-select__divider']">-</span>

        <VDatepicker
          :value="selectedRangeTo"
          :min="DateInputCustomDate.Now"
          :round-to="DateInputRoundDateTo.DayEnd"
          clearable
          @change="rangeToChanged"
        />
      </div>
      <div
        v-else
        :class="$style['custom-range-select__content']"
      >
        <DateInput
          class="date-picker--from"
          :class="[$style['custom-range-select__input']]"
          :value="selectedRangeFrom"
          :min="DateInputCustomDate.Now"
          :round-to="DateInputRoundDateTo.DayStart"
          :focused="focusedLocal"
          @change="rangeFromChanged"
          @focus="setFocused(FocusedInput.FROM)"
          @blur="setFocused(FocusedInput.NONE)"
        />

        <span :class="$style['custom-range-select__divider']">-</span>

        <DateInput
          class="date-picker--to"
          :class="[$style['custom-range-select__input']]"
          :value="selectedRangeTo"
          :min="DateInputCustomDate.Now"
          :round-to="DateInputRoundDateTo.DayEnd"
          :focused="focusedLocal"
          @change="rangeToChanged"
          @focus="setFocused(FocusedInput.TO)"
          @blur="setFocused(FocusedInput.NONE)"
        />
      </div>

      <div :class="$style['custom-range-select__suffix']">
        <VButton
          :kind="ButtonKind.TRANSPARENT"
          :icon-name="IconName.CANCEL"
          :icon-size="IconSize.SIZE_16"
          :class="$style['custom-range-select__cancel']"
          @click="hideSelectCustomRange"
        />
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .date-time-filter {
    @include z-index(sticky-tabs);

    position: sticky;
    display: flex;
    height: 44px;

    @if $isDesktop {
      top: -1 * $layoutDesktopSizeBetweenContentAndHeaderDesktopSmall;

      @include screen-desktop-large-min {
        top: -1 * $layoutDesktopSizeBetweenContentAndHeaderDesktopBig;
      }
    } @else {
      top: calc($headerHeightPhone + var(--StatusbarHeight));
    }
  }

  .custom-range-select {
    display: flex;
    flex: 1;
    height: calc(100% + 1px);
    padding-left: 12px;
    margin: if($isDesktop, 0, 0 8px);
    background-color: var(--Layer1);
    border: 1px solid var(--Blue);
    border-radius: 5px;

    &__prefix,
    &__suffix,
    &__content {
      display: flex;
      align-items: center;
    }

    &__prefix {
      justify-content: flex-start;
    }

    &__content {
      flex: 1;
      padding: 0 8px;
    }

    &__suffix {
      justify-content: flex-end;
    }

    &__input {
      padding: 0;
    }

    &__cancel {
      height: 42px;
    }

    &__divider {
      @include regular\14\16\025;

      padding: 0 10px;
      color: var(--TextSecondary);
      cursor: default;
      user-select: none;
    }
  }
}
</style>
