<script setup lang="ts">
import { toRef } from 'vue';

import type { MenuLevelClickPayload } from 'web/src/components/SidebarMenu/types';
import type { CustomerSportListElement } from 'web/src/modules/sportline/submodules/favorites/types';
import type { SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import SidebarCaption from 'web/src/components/SidebarMenu/SidebarCaption/SidebarCaption.vue';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';

import AllLeaguesLink from '../all-leagues-link/AllLeaguesLink.vue';
import TopLeaguesList from '../leagues-list/TopLeaguesList.vue';
import { useSportsSidebarTopLeagues } from './useSportsSidebarTopLeagues';

interface SportsSidebarTopLeaguesProps {
  defaultActiveSportId?: Maybe<string>;
  sports?: Maybe<SportsTreeSportElement[]>;
  favoritesSports?: Maybe<CustomerSportListElement[] | Readonly<CustomerSportListElement[]>>;
  maxTabsCount?: number;
  maxInList?: number;
}

interface SportsSidebarTopLeaguesEmits {
  (e: 'level-click', payload: { event: Event; payload?: MenuLevelClickPayload }): void;
}

const props = defineProps<SportsSidebarTopLeaguesProps>();
const emit = defineEmits<SportsSidebarTopLeaguesEmits>();

const sports = toRef(props, 'sports', []);
const favoritesSports = toRef(props, 'favoritesSports', []);
const maxTabsCount = toRef(props, 'maxTabsCount', 6);
const maxInList = toRef(props, 'maxInList', 5);
const defaultActiveSportId = toRef(props, 'defaultActiveSportId', null);

const {
  tabs,
  isAvailable,
  activeTabId,
  activeFilterTabLeaguesList,
  activeFilterTabLeaguesListSize,
  activeFilterTabAllLeaguesPageLocation,
  allLeaguesTitle,
  activeFilterTabTotalCount,
  onActivateTabClick,
} = useSportsSidebarTopLeagues({
  sports,
  favoritesSports,
  maxTabsCount,
  maxInList,
  defaultActiveSportId,
});
</script>

<template>
  <div v-auto-id="'SportsSidebarTopLeagues'"
    v-if="isAvailable"
    :class="$style['sports-sidebar-top-leagues']"
  >
    <SidebarCaption :title="$t('WEB2_TOP_LEAGUES')" />

    <VTabs
      :class="$style['sports-sidebar-top-leagues__tabs']"
      :type="TabsType.SMALL"
      :items="tabs"
      :active-id="activeTabId"
      is-bordered
      @tab-click="onActivateTabClick"
    />

    <TopLeaguesList
      :list="activeFilterTabLeaguesList"
      @level-click="emit('level-click', $event)"
    />

    <AllLeaguesLink
      v-if="activeFilterTabLeaguesListSize > maxInList"
      :page-location="activeFilterTabAllLeaguesPageLocation ?? ''"
      :title="allLeaguesTitle"
      :count="activeFilterTabTotalCount"
      @level-click="emit('level-click', $event)"
    />
  </div>
</template>

<style module lang="scss">
.sports-sidebar-top-leagues {
  margin-bottom: 16px;

  &__tabs {
    padding: 0 4px;
    margin-bottom: 4px;
  }
}
</style>
