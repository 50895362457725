<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { IconSize } from '@leon-hub/icons';

import { VCountryFlagSize } from '@components/country-flag';

import { FilledLogoBackgroundSize } from 'web/src/modules/sportline/components/SportlineLogo/enums';

import type { FilledSportlineLogoWithBackgroundProps } from './types';
import FilledSportlineLogo from './FilledSportlineLogo.vue';

const props = defineProps<FilledSportlineLogoWithBackgroundProps>();
const height = toRef(props, 'height', FilledLogoBackgroundSize.SIZE_40);
const sportSegmentId = toRef(props, 'sportSegmentId');

const sizeMapping: Record<FilledLogoBackgroundSize, IconSize> = {
  [FilledLogoBackgroundSize.FULL_SIZE]: IconSize.FULL_SIZE,
  [FilledLogoBackgroundSize.SIZE_84]: IconSize.SIZE_56,
  [FilledLogoBackgroundSize.SIZE_52]: IconSize.SIZE_32,
  [FilledLogoBackgroundSize.SIZE_40]: IconSize.SIZE_28,
  [FilledLogoBackgroundSize.SIZE_32]: IconSize.SIZE_24,
};
const flagSizeMapping: Record<FilledLogoBackgroundSize, VCountryFlagSize> = {
  [FilledLogoBackgroundSize.FULL_SIZE]: VCountryFlagSize.FULL_WIDTH,
  [FilledLogoBackgroundSize.SIZE_84]: VCountryFlagSize.SIZE_40,
  [FilledLogoBackgroundSize.SIZE_52]: VCountryFlagSize.SIZE_28,
  [FilledLogoBackgroundSize.SIZE_40]: VCountryFlagSize.SIZE_22,
  [FilledLogoBackgroundSize.SIZE_32]: VCountryFlagSize.SIZE_18,
};

const size = computed(() => sizeMapping[height.value]);
const flagSize = computed(() => flagSizeMapping[height.value]);
</script>

<template>
  <div v-auto-id="'FilledSportlineLogoWithBackground'"
    :class="{
      [$style['filled-sportline-logo-background']]: true,
      [$style[`filled-sportline-logo-background--${height}`]]: true,
    }"
  >
    <FilledSportlineLogo
      :src="src"
      :country-code="countryCode"
      :sport-icon-name="sportIconName"
      :sport-segment-id="sportSegmentId"
      :fill-color="fillColor"
      :size="size"
      :flag-size="flagSize"
      :borderless-flag="borderlessFlag"
    />
  </div>
</template>

<style module lang="scss">
.filled-sportline-logo-background {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--White);
  border-radius: $filledLogoBackgroundBorderRadius;

  &--full-size {
    flex: 0 0 100%;
    width: 100%;
    height: 100%;
  }

  &--84 {
    flex: 0 0 84px;
    width: 84px;
    height: 84px;
  }

  &--52 {
    flex: 0 0 52px;
    width: 52px;
    height: 52px;
  }

  &--40 {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
  }

  &--32 {
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
  }
}
</style>
