import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import type { PopperModifier, VPopperRef } from 'web/src/components/Popper/VPopper/types';

import type { VBreadcrumbProps } from '../types';

export interface VBreadcrumbComposable {
  popper: Ref<VPopperRef | undefined>;
  isLocallyOpen: Ref<boolean>;
  popperModifiers: PopperModifier[];
  isOpen: ComputedRef<boolean>;
  toggleOpen(): void;
  forceHide(): void;
}

export function useVBreadcrumb(props: VBreadcrumbProps): VBreadcrumbComposable {
  const popper = ref<VPopperRef>();
  const isLocallyOpen = ref(false);

  const popperModifiers: PopperModifier[] = [{
    name: 'computeStyles',
    fn(options) {
      options.state.styles.popper.transform = `translate3d(0, ${options.state.modifiersData.popperOffsets?.y || 0}px, 0)`;
      return options.state;
    },
  }];

  const isOpen = computed(() => !!(props.controlled ? props.open : isLocallyOpen.value));

  function toggleOpen() {
    isLocallyOpen.value = !isLocallyOpen.value;
  }

  function forceHide(): void {
    popper.value?.hide();
  }

  return {
    popper,
    isLocallyOpen,
    popperModifiers,
    isOpen,
    toggleOpen,
    forceHide,
  };
}
