<script setup lang="ts">
import { toRef } from 'vue';

import { VCountryFlag } from '@components/country-flag';
import { VIcon } from '@components/v-icon';

import type {
  FavoriteRegionFilterTab,
} from 'web/src/modules/sportline/submodules/favorites/components/FavoriteRegionsFilter/composables';
import SwiperNavigationButtons from 'web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import {
  useFavoriteRegionFilterSwiper,
} from 'web/src/modules/sportline/submodules/favorites/components/FavoriteRegionsFilter/composables';
import { getCyberSportIconName, getRegionCountryCode } from 'web/src/modules/sportline/utils/theme';

import type { SportlineFavoriteRegionsFilterProps as BaseProps } from './types';

interface SportlineFavoriteRegionsFilterProps extends BaseProps {
  activeTabIndex: number;
  favoriteRegionsTabs: FavoriteRegionFilterTab[];
  selectedRegionFamily?: Maybe<string>;
}

const props = defineProps<SportlineFavoriteRegionsFilterProps>();

const activeTabIndex = toRef(props, 'activeTabIndex');

const {
  swiper,
  slideToActive,
} = useFavoriteRegionFilterSwiper({ selectedIndex: activeTabIndex });
</script>

<template>
  <div v-auto-id="'SportlineFavoriteRegionsFilter'"
    v-if="favoriteRegionsTabs.length > 0"
    :class="$style['favorite-regions']"
  >
    <div :class="$style['favorite-regions-title']">
      <div :class="$style['favorite-regions-title__label']">
        {{ $t('WEB2_FAVORITE_COUNTRIES') }}
      </div>
    </div>

    <VSwiper
      ref="swiper"
      is-scroll-snap-enabled
      :class="$style['favorite-regions-list']"
      @vue:mounted="slideToActive(false)"
    >
      <VSwiperSlide
        v-for="(item, index) in favoriteRegionsTabs"
        :key="item.key"
        :class="$style['favorite-regions-list__slide']"
        @click="item.onClick(item, index)"
      >
        <div
          v-data-test="{ el: 'favorite-regions-filter', active: item.isActive }"
          :class="{
            [$style['favorite-regions-list__item']]: true,
            [$style['favorite-regions-list__item--active']]: item.isActive,
          }"
        >
          <template v-if="item.region">
            <VIcon
              v-if="item.isCyberSport"
              :name="getCyberSportIconName(item.region.representation)"
              :class="$style['favorite-regions-list__icon']"
            />
            <VCountryFlag
              v-else
              :code="getRegionCountryCode(item.region)"
              :class="$style['favorite-regions-list__flag']"
            />
          </template>

          {{ item.title }}
        </div>
      </VSwiperSlide>
      <template
        v-if="'1'"
        #pagination-footer
      >
        <SwiperNavigationButtons />
      </template>
    </VSwiper>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .favorite-regions-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    padding: if($isDesktop, 0 0 0 16px, 0 0 0 8px);

    &__label {
      @include medium\18\20;
    }
  }

  .favorite-regions-list {
    &__slide {
      padding-right: 8px;
      padding-left: 0;

      &:last-child {
        padding-right: 0;
      }
    }

    &__item {
      @include medium\14\20\025;

      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 44px;
      padding: 0 16px;
      color: var(--TextPrimary);
      text-decoration: none;
      cursor: pointer;
      background-color: var(--Layer1);
      border: 2px solid transparent;
      border-radius: var(--BorderRadius);
      box-shadow: none;

      &:hover {
        color: var(--TextDefault);
        border-color: var(--Layer2);
      }

      &--active {
        color: var(--TextDefault);
        border-color: var(--Highlight);
      }
    }

    &__flag {
      width: 22px;
      height: 16px;
      margin-right: 8px;
      border-radius: 2px;
    }

    &__icon {
      margin-right: 8px;
    }
  }
}
</style>
