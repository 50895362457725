import type { Ref } from 'vue';
import {
  computed,
  nextTick,
  ref,
  watch,
} from 'vue';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import type {
  LeagueStandingsTournament,
  TeamStandingPromotion,
} from 'web/src/modules/sportline/submodules/core-statistic/types';
import type {
  BetlineLeagueStanding,
  BetlineMatchStatisticsTeam,
} from 'web/src/modules/sportline/types/rest';
import {
  filterGroupsInTournamentByFirstAndLastPosition,
  filterGroupsInTournamentByTeams,
  getPromotionsFromTournament,
  groupLeagueStandings,
} from 'web/src/modules/sportline/submodules/core-statistic/utils';

interface UseStatisticLeagueStandingsProps {
  leagueStandings: Ref<BetlineLeagueStanding[]>;
  teams: Ref<Maybe<[BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam]>>;
  alwaysExpanded: Ref<boolean>;
}

interface UseStatisticLeagueStandingsComposable {
  isExpanded: Ref<boolean>;
  isDisplayFullTable: Ref<boolean>;
  teamsIds: Ref<Set<string>>;
  tournament: Ref<Maybe<LeagueStandingsTournament>>;
  promotions: Ref<TeamStandingPromotion[]>;
}

export default function useStatisticLeagueStandings(
  props: UseStatisticLeagueStandingsProps,
): UseStatisticLeagueStandingsComposable {
  const bus = useEventsBus();

  const {
    leagueStandings,
    teams,
    alwaysExpanded,
  } = props;

  const standings = ref<HTMLDivElement | undefined>();
  const isExpanded = ref(!!process.env.VUE_APP_PRERENDER);
  const isDisplayFullTable = computed<boolean>(() => alwaysExpanded.value || isExpanded.value);

  const teamsIds = computed<Set<string>>(() => {
    if (!teams.value || teams.value.length < 2) { return new Set([]); }
    const hostId = teams.value[0].id;
    const guestId = teams.value[1].id;
    return new Set([hostId, guestId].filter((id): id is string => !!id));
  });
  const tournament = computed<Maybe<LeagueStandingsTournament>>(() => {
    const tournamentRaw = groupLeagueStandings(leagueStandings.value);

    if (isDisplayFullTable.value) {
      return tournamentRaw;
    }

    if (teamsIds.value.size < 2) {
      return filterGroupsInTournamentByFirstAndLastPosition(tournamentRaw);
    }

    return filterGroupsInTournamentByTeams(tournamentRaw, teamsIds.value);
  });
  const promotions = computed<TeamStandingPromotion[]>(() => {
    const promotionsMap = getPromotionsFromTournament(tournament.value);
    return [...promotionsMap.values()];
  });

  if (!process.env.VUE_APP_PRERENDER) {
    watch(alwaysExpanded, (newValue: boolean) => {
      isExpanded.value = newValue;
    }, { immediate: true });

    watch(isExpanded, async (newValue: boolean) => {
      if (newValue) { return; }

      await nextTick();
      const top = standings.value?.offsetTop;
      const headerHeight = 60; // beside value

      if (top === undefined) { return; }

      bus.emit(BusEvent.LAYOUT_CONTENT_SET_SCROLL, {
        scrollTop: top - headerHeight,
        smooth: true,
      });
    });
  }

  return {
    isExpanded,
    isDisplayFullTable,
    promotions,
    teamsIds,
    tournament,
  };
}
