import type { Ref } from 'vue';
import { computed } from 'vue';

import { useI18n } from '@core/i18n';

import type {
  Region,
  SportElement,
} from 'web/src/modules/sportline/types';
import { SportSegmentId } from 'web/src/modules/sportline/enums';

export interface FavoriteRegionFilterTab {
  key: string;
  title: string;
  isCyberSport: boolean;
  isActive: boolean;
  region?: Region;
  onClick(item?: FavoriteRegionFilterTab, index?: number): void;
  testData?: string;
}

export interface FavoriteRegionFilterTabsProps {
  selectedRegionFamily: Ref<Maybe<string>>;
}

export interface FavoriteRegionFilterTabsEmits {
  selectRegionFamily(selectedRegionFamily: Maybe<string>): void;
}

export interface FavoriteRegionFilterTabsComposable {
  activeTabIndex: Ref<number>;
  favoriteRegionsTabs: Ref<FavoriteRegionFilterTab[]>;
}

export function useFavoriteRegionFilterTabs(
  props: FavoriteRegionFilterTabsProps,
  emits: FavoriteRegionFilterTabsEmits,
  favoriteEventsList: Ref<SportElement[]>,
): FavoriteRegionFilterTabsComposable {
  const { selectedRegionFamily } = props;
  const { selectRegionFamily } = emits;

  const { $translate } = useI18n();

  const favoriteRegionsTabs = computed(() => {
    const selectedFamily = selectedRegionFamily.value;
    const regionMap = new Map<string, FavoriteRegionFilterTab>();
    const favoritesList = favoriteEventsList.value;

    if (favoritesList && favoritesList.length > 0) {
      for (const sportElement of favoritesList) {
        for (const regionElement of sportElement.regions) {
          if (!regionMap.get(regionElement.region.regionFamily)) {
            regionMap.set(regionElement.region.regionFamily, {
              key: regionElement.region.regionFamily,
              title: regionElement.region.name,
              isActive: regionElement.region.regionFamily === selectedFamily,
              isCyberSport: sportElement.sport.segment.id === SportSegmentId.CyberSport,
              region: regionElement.region,
              onClick: () => {
                selectRegionFamily(regionElement.region.regionFamily);
              },
            });
          }
        }
      }
    }

    if (regionMap.size < 2) {
      return [];
    }

    const allRegionsTab: FavoriteRegionFilterTab = {
      key: 'all-favorites-regions-tab',
      title: $translate('JSP_SPORT_NAV_DEFAULT_REGION').value,
      isActive: selectedFamily === null,
      isCyberSport: false,
      onClick: () => {
        selectRegionFamily(null);
      },
    };

    return [allRegionsTab, ...regionMap.values()];
  });
  const activeTabIndex = computed(() => favoriteRegionsTabs.value.findIndex((tab) => tab.isActive));

  return {
    activeTabIndex,
    favoriteRegionsTabs,
  };
}
