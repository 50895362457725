import type {
  BetlinePostMatchStatistics,
  BetlinePostMatchStatisticsCard,
} from 'web/src/modules/sportline/types/rest';
import type {
  SportlineMatchStatisticsCard,
  SportlineMatchStatisticsPlayer,
  SportlinePostMatchStatistics,
} from 'web/src/modules/sportline/types/statistics';

function isNotEmpty<T>(value?: Maybe<T>): value is T {
  return !!value;
}

export function convertBetlineToSportlineMatchStatisticsPlayer(
  response?: Maybe<BetlinePostMatchStatisticsCard['playerDto']>,
): Maybe<SportlineMatchStatisticsPlayer> {
  if (!response) { return null; }
  const {
    shirtNumber,
    playerName,
    playerShortName,
  } = response;

  return {
    shirtNumber,
    playerName,
    playerShortName,
  };
}

export function convertBetlineToSportlineMatchStatisticsCard(
  response?: Maybe<BetlinePostMatchStatisticsCard>,
): Maybe<SportlineMatchStatisticsCard> {
  if (!response) { return null; }
  const {
    team,
    time,
    color,
    playerDto,
  } = response;

  return {
    team,
    time,
    color,
    playerDto: convertBetlineToSportlineMatchStatisticsPlayer(playerDto),
  };
}

export function convertBetlineToSportlinePostMatchStatistics(response?: Maybe<BetlinePostMatchStatistics | false>): Maybe<SportlinePostMatchStatistics> | false {
  if (response === false) { return false; }
  if (!response) { return null; }
  const {
    id,
    matchDate,
    family,
    cards,
    substitutions,
    teams,
    resultScore,
    goals,
    scores,
    periods,
  } = response;

  return {
    id,
    matchDate,
    family,
    cards: cards?.map(convertBetlineToSportlineMatchStatisticsCard).filter(isNotEmpty) ?? [],
    substitutions,
    teams,
    resultScore,
    goals,
    scores,
    periods,
  };
}
