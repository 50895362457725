import type { Ref } from 'vue';
import {
  computed,
  nextTick,
  onActivated,
  ref,
  watch,
} from 'vue';

import type { VSwiperRef } from 'web/src/components/Swiper/VSwiper/types';
import { FilterIdentificator } from 'web/src/modules/sportline/enums';

import type { SportlineFilterItem } from '../types';

interface UseSportlineFilterProps {
  items: Ref<SportlineFilterItem[]>;
}

interface UseSportlineFilterComposable {
  swiper: Ref<Optional<VSwiperRef>>;
  selectedIndex: Ref<number>;
  itemsWithIdentifier: Ref<SportlineFilterItem[]>;
  slideToActive(smooth: boolean): Promise<void>;
}

export function useSportlineFilter(
  props: UseSportlineFilterProps,
): UseSportlineFilterComposable {
  const { items } = props;

  const swiper = ref<Optional<VSwiperRef>>();

  const selectedIndex = computed<number>(() => {
    const index = items.value.findIndex((item) => item.isActive);
    return index > -1 ? index : 0;
  });
  const itemsWithIdentifier = computed<SportlineFilterItem[]>(() => (items.value.map((item) => {
    const dataTest = item.customFilter ? FilterIdentificator.CUSTOM : FilterIdentificator.SPORT;
    return { ...item, dataTest };
  })));

  async function slideToActive(smooth = false): Promise<void> {
    await nextTick();
    swiper.value?.slideToSlide(selectedIndex.value, {
      isCentered: true,
      smooth,
      speed: 300,
    });
  }

  watch(selectedIndex, () => slideToActive(true));
  onActivated(() => slideToActive(false));

  return {
    swiper,
    selectedIndex,
    itemsWithIdentifier,
    slideToActive,
  };
}
