import { assert, isEnumOfType } from '@leon-hub/guards';

import { CountryCode } from '@components/country-flag';

import type { Region } from 'web/src/modules/sportline/types';

/**
 * It just forces set the representation icon's type to CountryCode
 */
export function getRegionCountryCode(region?: Maybe<Region>): Maybe<CountryCode> {
  const icon = region?.representation.icon;
  if (!icon) {
    return null;
  }
  assert(isEnumOfType(CountryCode)(icon));
  return icon;
}
