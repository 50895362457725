import type { TimelineMark, TimelineTimePart } from 'web/src/modules/sportline/submodules/core-statistic/types';
import type { BetlinePostMatchStatisticsScore } from 'web/src/modules/sportline/types/rest';
import { BetlineStatisticScoresType } from 'web/src/modules/sportline/enums/rest';
import { TimelineMarkType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import { compareAsc } from 'web/src/utils/sort';

function fixPositionToBorders(position: number, min?: number, max?: number): number {
  if (min !== undefined && position < min) { return min; }
  if (max !== undefined && position > max) { return max; }
  return position;
}

export function filterEmptyMark(mark: Maybe<TimelineMark>): mark is TimelineMark {
  return mark !== null;
}

export function filterSameTypeMarks(mark: TimelineMark, index: number, list: TimelineMark[]): mark is TimelineMark {
  return list.findIndex((searched) => searched.type === mark.type) === index;
}

export function sortMarksByAsc(marks: TimelineMark[]): TimelineMark[] {
  return marks.sort((a, b) => compareAsc(a.position, b.position));
}

export function marksApproximation(
  marks: TimelineMark[],
  options: {
    minDistance: number;
    minPosition?: number;
    maxPosition?: number;
  },
  currentMark: Maybe<TimelineMark> = null,
  result: TimelineMark[] = [],
): TimelineMark[] {
  const [nextMark, ...otherMarks] = marks;

  if (!nextMark) {
    // last mark
    return currentMark ? [...result, {
      ...currentMark,
      position: fixPositionToBorders(currentMark.position, options.minPosition, options.maxPosition),
    }] : result;
  }

  if (!currentMark) {
    // first mark
    return marksApproximation(otherMarks, options, nextMark, result);
  }

  const distance = Math.abs(currentMark.position - nextMark.position);

  if (distance < options.minDistance) {
    const currentPosition = fixPositionToBorders(currentMark.position, options.minPosition, options.maxPosition);
    const middle = (currentPosition + nextMark.position) / 2;
    const time = (currentMark.time + nextMark.time) / 2;
    const position = Math.round(middle * 10) / 10;

    const mergedMark: TimelineMark = {
      ...currentMark,
      time,
      label: `${Math.round(time)}’`,
      position,
      hostIncidents: [...currentMark.hostIncidents, ...nextMark.hostIncidents],
      guestIncidents: [...currentMark.guestIncidents, ...nextMark.guestIncidents],
    };

    return marksApproximation(otherMarks, options, mergedMark, result);
  }

  return marksApproximation(otherMarks, options, nextMark, [...result, {
    ...currentMark,
    position: fixPositionToBorders(currentMark.position, options.minPosition, options.maxPosition),
  }]);
}

function getFirstTimeMarks(marks: TimelineMark[]): TimelineMark[] {
  const filtered = marks.filter((mark) => mark.position <= 50);
  return filtered.map((mark) => ({ ...mark, position: mark.position * 2 }));
}

function getSecondTimeMarks(marks: TimelineMark[]): TimelineMark[] {
  const filtered = marks.filter((mark) => mark.position > 50 && mark.position <= 100);
  return filtered.map((mark) => ({ ...mark, position: (mark.position - 50) * 2 }));
}

function getExtraTimeMarks(marks: TimelineMark[]): TimelineMark[] {
  const filtered = marks.filter((mark) => mark.position > 100);
  return filtered.map((mark) => ({ ...mark, position: (mark.position - 100) * 2 }));
}

export function splitMarksByTimes(
  incomingMarks: TimelineMark[],
  scores: ReadonlyArray<BetlinePostMatchStatisticsScore>,
): TimelineTimePart[] {
  const map = new Map<TimelineMarkType, TimelineMark[]>([]);

  map.set(TimelineMarkType.HALF_TIME, getFirstTimeMarks(incomingMarks));
  map.set(TimelineMarkType.FULL_TIME, getSecondTimeMarks(incomingMarks));

  if (scores.some((score) => score.type === BetlineStatisticScoresType.OverTime
    || score.type === BetlineStatisticScoresType.AfterPenalty)) {
    map.set(TimelineMarkType.FULL_EXTRA_TIME, getExtraTimeMarks(incomingMarks));
  }

  return [...map.entries()].map(([type, marks]) => ({ type, marks }));
}
