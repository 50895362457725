<script lang="ts" setup>
import { VRouterLink } from '@components/link';

import type { MenuLevelClickPayload } from 'web/src/components/SidebarMenu/types';
import type { ActiveTopLeagueListElement } from 'web/src/modules/sportline/submodules/sidebar/types';
import { MenuLevel } from 'web/src/components/SidebarMenu/types';

interface TopLeaguesListProps {
  list: ActiveTopLeagueListElement[];
}

interface TopLeaguesListEmits {
  (e: 'level-click', payload: { event: MouseEvent; payload?: MenuLevelClickPayload }): void;
}

defineProps<TopLeaguesListProps>();
const emit = defineEmits<TopLeaguesListEmits>();

function emitMenuLevelClick(event: MouseEvent, payload: MenuLevelClickPayload): void {
  emit('level-click', { event, payload });
}
</script>

<template>
  <div v-auto-id="'TopLeaguesList'"
    v-for="topLeagueElement in list"
    :key="topLeagueElement.league.id"
    :class="$style['league-list']"
  >
    <VRouterLink
      :to="topLeagueElement.pageLocation"
      :class="$style['league-list__league']"
      @click="emitMenuLevelClick($event, { level: MenuLevel.FREE, id: topLeagueElement.league.id })"
    >
      <div
        v-if="topLeagueElement.region"
        :class="$style['league-list__region']"
      >
        {{ topLeagueElement.region.name }}
      </div>
      <div :class="$style['league-list__title']">
        {{ topLeagueElement.league.name }}
      </div>
    </VRouterLink>
  </div>
</template>

<style module lang="scss">
.league-list {
  margin-bottom: 4px;
  border-radius: 5px;

  @include for-hover {
    &:hover {
      background-color: var(--Layer1);
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &__league {
    display: block;
    padding: 6px 12px;
    text-decoration: none;
  }

  &__region {
    @include medium\12\12;

    margin-bottom: 2px;
    color: var(--TextSecondary);
  }

  &__title {
    @include medium\14\16\025;

    color: var(--TextPrimary);
  }
}
</style>
