<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';

import { IconName, IconSize } from '@leon-hub/icons';

import { VRouterLink } from '@components/link';
import { VIcon } from '@components/v-icon';

import type { MenuLevelClickPayload } from 'web/src/components/SidebarMenu/types';

interface AllLeaguesLinkProps {
  pageLocation: RouteLocationRaw;
  title: string;
  count: number;
}

interface AllLeaguesLinkEmits {
  (e: 'level-click', payload: { event: Event; payload?: MenuLevelClickPayload }): void;
}

defineProps<AllLeaguesLinkProps>();
const emit = defineEmits<AllLeaguesLinkEmits>();

function emitMenuLevelZeroClick(event: Event): void {
  emit('level-click', { event });
}
</script>

<template>
  <div v-auto-id="'AllLeaguesLink'"
    :class="[
      $style['all-leagues-link'],
      $style['sports-level-1-spoiler'],
    ]"
  >
    <VRouterLink
      :class="[
        $style['all-leagues-link__header'],
        $style['sports-level-1-spoiler__header'],
      ]"
      :to="pageLocation"
      @click="emitMenuLevelZeroClick($event)"
    >
      <span
        :class="[
          $style['all-leagues-link__title'],
          $style['sports-level-1-spoiler__title'],
        ]"
      >
        {{ title }}
      </span>

      <span :class="$style['sports-level-1-spoiler__indicator']">
        <span :class="$style['all-leagues-link__counter']">
          {{ count }}
        </span>

        <VIcon
          :size="IconSize.SIZE_16"
          :name="IconName.EXPAND_RIGHT"
        />
      </span>
    </VRouterLink>
  </div>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/styles/sport-spoiler';

.all-leagues-link {
  &__header {
    height: 40px;

    &:hover {
      background-color: var(--Layer1);
    }
  }

  &__title {
    @include medium\14\16\025;

    margin-left: 12px;
  }

  &__counter {
    @include medium\12\16;

    padding: 0 4px;
    margin-right: 4px;
    color: var(--Layer0);
    background-color: var(--TextPrimary);
    border-radius: 8px;
  }
}
</style>
