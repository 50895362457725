import type { MaybeRef, Ref, WatchStopHandle } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import type {
  FilterTransitionElementRef,
} from 'web/src/modules/sportline/components/FilterTransition/types';
import type { SportlinePersistentLocationKey } from 'web/src/modules/sportline/submodules/persist-filters/types';
import type {
  ChangeFilterPayload,
} from 'web/src/modules/sportline/submodules/segment/composables/types';
import type {
  SegmentPageListGroup,
  SportEventsSegmentSelectedFilter,
} from 'web/src/modules/sportline/submodules/segment/types';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { useResetPersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';

export interface SegmentPageResetFiltersHooksProps<Group extends SegmentPageListGroup = SegmentPageListGroup> {
  persistentLocationKey?: MaybeRef<SportlinePersistentLocationKey>;
  isReady: Ref<boolean>;
  selectedFilter: Ref<SportEventsSegmentSelectedFilter>;
  groupsForDisplay: Ref<Group[]>;
  setBackgroundUpdateEnabled(value: boolean): void;
  getDefaultPageLocation(): RouteLocationRaw;
}

export interface SegmentPageResetFiltersHooksComposable {
  doRedirectFromTab: Ref<boolean>;
  listTransition: Ref<Maybe<FilterTransitionElementRef>>;
  onChangeFilter(payload: Maybe<ChangeFilterPayload>): void;
  onActivatedFiltersHook(): void;
  onDeactivatedFiltersHook(): void;
}

function isGroupCanBeShown<Group extends SegmentPageListGroup = SegmentPageListGroup>(group: Group): boolean {
  return group.isActive && (!group.isEmpty || !group.isEmptyComing);
}

function doNotRedirectFromFilter(filter: string | CustomFilter): boolean {
  return filter as CustomFilter === CustomFilter.AllEvents
    || filter as CustomFilter === CustomFilter.None;
}

export function useSegmentPageResetFiltersHooks<Group extends SegmentPageListGroup = SegmentPageListGroup>(
  props: SegmentPageResetFiltersHooksProps<Group>,
): SegmentPageResetFiltersHooksComposable {
  const {
    isReady,
    selectedFilter,
    groupsForDisplay,
    persistentLocationKey,
    getDefaultPageLocation,
    setBackgroundUpdateEnabled,
  } = props;

  const router = useRouter();

  const { handlePersistLocationReset } = useResetPersistLocation();

  let unwatchRedirectFromEmptyTab: Maybe<WatchStopHandle> = null;

  // ref to filter element
  const listTransition = ref<Maybe<FilterTransitionElementRef>>(null);

  // @TODO merge logic with SportEventsAbstractTab.needRedirectFromTab
  const doRedirectFromTab = computed<boolean>(() => {
    if (!isReady.value) { return false; }
    if (doNotRedirectFromFilter(selectedFilter.value)) { return false; }
    return !groupsForDisplay.value.find(isGroupCanBeShown);
  });

  function onChangeFilter(payload: Maybe<ChangeFilterPayload>): void {
    // stop navigation for active transition
    if (listTransition.value?.isActive()) { return; }
    const to = payload?.to || getDefaultPageLocation();
    listTransition.value?.enable();
    if (to) { void router.replace(to); }
  }

  function onActivatedFiltersHook(): void {
    setBackgroundUpdateEnabled(true);
    unwatchRedirectFromEmptyTab = unwatchRedirectFromEmptyTab ?? watch(
      doRedirectFromTab,
      (doRedirect: boolean) => {
        if (!doRedirect) { return; }
        onChangeFilter(null);
      },
      { immediate: true },
    );
  }

  function onDeactivatedFiltersHook(): void {
    unwatchRedirectFromEmptyTab?.();
    unwatchRedirectFromEmptyTab = null;

    setBackgroundUpdateEnabled(false);
    handlePersistLocationReset(persistentLocationKey);
  }

  return {
    listTransition,
    doRedirectFromTab,
    onChangeFilter,
    onActivatedFiltersHook,
    onDeactivatedFiltersHook,
  };
}
