<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from 'vue';

import type { VLazyRenderEmits, VLazyRenderProps } from './types';
import { useVLazyRender } from './composables';

const props = withDefaults(defineProps<VLazyRenderProps>(), {});
const emit = defineEmits<VLazyRenderEmits>();

const {
  collision,
  mounted,
  stopObserving,
  displayContent,
} = useVLazyRender(props, emit);

onMounted(mounted);
onBeforeUnmount(stopObserving);
</script>

<template>
  <div v-auto-id="'VLazyRender'" ref="collision">
    <slot v-if="displayContent" />
  </div>
</template>
