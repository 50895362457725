import type { Ref } from 'vue';

import type {
  SegmentPageListGroup,
  SportEventsSegmentSelectedFilter,
  SportlineSegmentFilterActiveTransition,
} from 'web/src/modules/sportline/submodules/segment/types';

interface UseCanShowSegmentGroupProps {
  selectedFilter: Ref<SportEventsSegmentSelectedFilter>;
  activeTransitionId: Ref<SportlineSegmentFilterActiveTransition>;
}

interface UseCanShowSegmentGroupComposable {
  canShowLiveForGroup(group: SegmentPageListGroup): boolean;
  canShowPrematchForGroup(group: SegmentPageListGroup): boolean;
}

export function useCanShowSegmentGroup(
  props: UseCanShowSegmentGroupProps,
): UseCanShowSegmentGroupComposable {
  const {
    selectedFilter,
    activeTransitionId,
  } = props;

  function canShowLiveForGroup(group: SegmentPageListGroup): boolean {
    return activeTransitionId.value
      ? group.allowedForTransitionIds.includes(activeTransitionId.value)
      : !!selectedFilter.value
      && group.allowedForTransitionIds.includes(selectedFilter.value);
  }

  function canShowPrematchForGroup(group: SegmentPageListGroup): boolean {
    return activeTransitionId.value
      ? activeTransitionId.value === group.transitionId
      : !!selectedFilter.value
      && group.transitionId === selectedFilter.value;
  }

  return { canShowLiveForGroup, canShowPrematchForGroup };
}
