import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import { logger } from '@leon-hub/logging';

import type {
  SportEventsSegmentSelectedFilter,
} from 'web/src/modules/sportline/submodules/segment/types';
import type {
  DateTimeRange,
  RegionElement,
  SportElement,
} from 'web/src/modules/sportline/types';
import type {
  BuildSportOptions,
  CoreSportlineFetchOptions,
  GetSportsResponse,
  SportsTreeSportElement,
} from 'web/src/modules/sportline/types/rest';
import type { SportSegmentSettings } from 'web/src/modules/sportline/types/settings';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { isCustomFilter } from 'web/src/modules/sportline/guards';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { chooseFilterValue } from 'web/src/modules/sportline/submodules/segment/store/utils';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import { isLiveCountersEmpty, isSportFamilyEquals } from 'web/src/modules/sportline/utils';
import { SportsListAdapter } from 'web/src/modules/sportline/utils/rest';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';

interface SegmentStoreFilterProps {
  parseSportlineSettings: Ref<BuildSportOptions>;
  sportSegmentsSettings: Ref<SportSegmentSettings>;
  comingEventsKickoffPeriod: Ref<DateTimeRange>;
  allLiveTabUpdateInterval: Ref<number>;
  selectedLiveTabUpdateInterval: Ref<number>;
}

interface SegmentStoreFilterComposable {
  isReady: Ref<boolean>;
  filterUpdateInterval: Ref<number>;
  fullSportsList: Ref<SportsTreeSportElement[]>;
  fullDisciplinesList: Ref<Maybe<RegionElement[]>>;
  selectedFilter: Ref<SportEventsSegmentSelectedFilter>;
  rawFullSportsListResponse: Ref<Maybe<Readonly<GetSportsResponse>>>;
  activeSportElement: Ref<Maybe<SportElement>>;
  activeRegionElement: Ref<Maybe<RegionElement>>;
  filterOptionsIds: Ref<string[]>;
  setSelectedFilter(filter?: SportEventsSegmentSelectedFilter): void;
  initialRequests(): Promise<void>;
  onInit(): void;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
  syncBackgroundRequests(options: { force?: boolean; silent?: boolean }): Promise<void>;
}

export function useSegmentStoreFilterComposable(
  props: SegmentStoreFilterProps,
): SegmentStoreFilterComposable {
  const {
    sportSegmentsSettings,
    allLiveTabUpdateInterval,
    selectedLiveTabUpdateInterval,
  } = props;

  const isUpdateOnlyActiveEntityEnabled = toRef(() => sportSegmentsSettings.value.isUpdateOnlyActiveEntityEnabled);

  const apiService = useSportlineApiService();

  const selectedFilter = ref<SportEventsSegmentSelectedFilter>(CustomFilter.None);
  const rawFullSportsListResponse = ref<Maybe<Readonly<GetSportsResponse>>>(null);

  const lastUpdate = new BackgroundUpdateStopwatch<['filterOptions']>({
    filterOptions: async ({ silent }: CoreSportlineFetchOptions) => {
      const response = await apiService.loadLiveFiltersSportsTree({
        silent,
        dateTime: {
          to: props.comingEventsKickoffPeriod.value.to,
          from: undefined,
        },
      });

      rawFullSportsListResponse.value = response || null;
      lastUpdate.update('filterOptions');
    },
  });

  /** Update only one sport/region for active tab */
  const filterUpdateInterval = computed<number>(() => (
    isUpdateOnlyActiveEntityEnabled.value
      ? selectedLiveTabUpdateInterval.value
      : allLiveTabUpdateInterval.value
  ));
  const isReady = computed(() => rawFullSportsListResponse.value !== null);
  const segmentSportFamily = computed(() => props.sportSegmentsSettings.value.sportFamily);
  const fullSportsList = computed<SportsTreeSportElement[]>(() => {
    const rawResponse = rawFullSportsListResponse.value;

    if (!rawResponse) {
      return [];
    }

    const list = (new SportsListAdapter(rawResponse, {
      ...props.parseSportlineSettings.value,
      responseFilters: {
        league: [(response) => ((response.inplay || 0) + (response.prematch || 0) > 0)],
      },
    })).getSports();

    return list.filter((listElement) => listElement.leaguesCount > 0);
  });
  // full sport elements list
  const sportElementsList = computed<SportElement[]>(() => fullSportsList.value
    .map((sportListElement): SportElement => ({
      key: sportListElement.sport.id,
      sport: sportListElement.sport,
      regions: [],
      marketTypes: [],
      marketsColumns: [],
      counters: sportListElement.counters,
    })));
  const activeSportElement = computed<Maybe<SportElement>>(() => {
    // find sport for segment
    if (segmentSportFamily.value) {
      const sportFamily = segmentSportFamily.value;
      const list = fullSportsList.value
        .filter((element) => element.sport.representation.family === sportFamily)
        .map((sportListElement): SportElement => ({
          key: sportListElement.sport.id,
          sport: sportListElement.sport,
          regions: (sportListElement.regions ?? []).map((regionListElement) => ({
            key: regionListElement.region.id,
            region: regionListElement.region,
            marketTypes: [],
            leagues: [],
            counters: regionListElement.counters,
          })),
          marketTypes: [],
          marketsColumns: [],
          counters: sportListElement.counters,
        }));

      if (list.length > 1) {
        logger.warn(`More then one "${sportFamily}" sport presented`, list.map((sportElement) => sportElement.sport.id));
      }

      return list?.[0] || null;
    }

    // ex. sportElementSelectedInFilter - find selected sport for filter
    const selectedName = selectedFilter.value;
    const isCustomFilterSelected = isCustomFilter(selectedName);

    if (isCustomFilterSelected) {
      return null;
    }

    const selectedSportItem = sportElementsList.value
      .find((sportElement) => isSportFamilyEquals(sportElement.sport, selectedName));

    return selectedSportItem ?? null;
  });
  const fullDisciplinesList = computed<Maybe<RegionElement[]>>(() => {
    // sports pages don't have disciplines list
    if (!segmentSportFamily.value) {
      return null;
    }
    if (!activeSportElement.value) {
      return null;
    }
    return activeSportElement.value.regions
      .filter((regionElement) => (!isLiveCountersEmpty(regionElement) || regionElement.counters.prematch > 0));
  });
  const activeRegionElement = computed<Maybe<RegionElement>>(() => {
    if (!segmentSportFamily.value) {
      return null;
    }

    const selectedName = selectedFilter.value;
    const isCustomFilterSelected = isCustomFilter(selectedName);

    if (isCustomFilterSelected) {
      return null;
    }

    return (fullDisciplinesList.value ?? [])
      .find((regionElement) => regionElement
        .region
        .navigationParameters
        .urlName
        .toLowerCase() === selectedName) ?? null;
  });
  const filterOptionsIds = computed<string[]>(() => {
    if (!segmentSportFamily.value) {
      // return all sports family
      return sportElementsList.value
        .map((sportElement) => sportElement.sport.representation.family) || [];
    }

    // return regions ids
    return fullDisciplinesList.value?.map((regionElement) => regionElement.region.id) ?? [];
  });

  const {
    initialRequests,
    syncState,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval: filterUpdateInterval,
  });

  function setSelectedFilter(value?: SportEventsSegmentSelectedFilter): void {
    const filter = value || CustomFilter.AllEvents;

    // do not update the same filter
    if (filter.toLowerCase() === selectedFilter.value.toLowerCase()) {
      return;
    }

    selectedFilter.value = chooseFilterValue(filter);
  }

  function onInit(): void {
    useSyncSportline(async (silent: boolean): Promise<void> => {
      await syncState({ silent });
    }, filterUpdateInterval);
  }

  return {
    isReady,
    filterUpdateInterval,
    selectedFilter,
    rawFullSportsListResponse,
    fullSportsList,
    fullDisciplinesList,
    activeSportElement,
    activeRegionElement,
    filterOptionsIds,
    setSelectedFilter,
    initialRequests,
    onInit,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  };
}
