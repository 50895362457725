import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import type { MenuLevelClickPayload } from 'web/src/components/SidebarMenu/types';
import { LayoutSizes } from 'web/src/modules/core/enums';

import { getSidebarMenuCatalogClickPayload } from '../utils';

interface UseSidebarClickComposable {
  catalogClick(payload: { event: MouseEvent; payload?: MenuLevelClickPayload }): void;
}

export function useSidebarClick(): UseSidebarClickComposable {
  const bus = useEventsBus();

  function catalogClick({ event, payload }: { event: MouseEvent; payload?: MenuLevelClickPayload }): void {
    if (payload) {
      const catalogClickPayload = getSidebarMenuCatalogClickPayload(payload);

      if (catalogClickPayload) {
        bus.emit(BusEvent.CATALOG_CLICK, { event, payload: catalogClickPayload });
      }
    }

    if (document.documentElement.clientWidth < LayoutSizes.ShowLeftSidebarBreakpoint) {
      bus.emit(BusEvent.SIDE_MENU_TOGGLE, {});
    }
  }

  return { catalogClick };
}
