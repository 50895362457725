<script lang="ts" setup>
import { computed, ref, toRef } from 'vue';

import type { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event';
import type {
  LeagueElement,
  Region,
  Sport,
} from 'web/src/modules/sportline/types';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';
import type { UpcomingLabelType } from 'web/src/modules/sportline/views/EventBlock/enums';
import { useExpandableSportlineBlock } from 'web/src/modules/sportline/composables/list';
import SportlineEventBlock from 'web/src/modules/sportline/views/EventBlock/SportlineEventBlock.vue';
import LeagueHeadline from 'web/src/modules/sportline/views/headline/LeagueHeadline.vue';

interface SportlineLeagueBlockProps {
  sport: Sport;
  region: Region;
  leagueElement: LeagueElement;
  upcomingLabelType?: Maybe<UpcomingLabelType>;
  isHideLeagueInfo?: boolean;
  isColumnNamesDisplayDisabled?: boolean;
  isShowAddLeagueToFavorite?: boolean;
  isDisplayMainEventsDisabled?: boolean;
  isDisplayOutrightEventsEnabled?: boolean;
  isDisplayOutrightHeadlineDisabled?: boolean;
  canAddToFavorite?: boolean;
  canAddOutrightsToFavorite?: boolean;
  isExtendedEventViewDisabled?: boolean;
  activeEventId?: Maybe<string>;
  isFilterActive?: boolean;
  expandKey?: Maybe<string>;
  isDefaultExpanded?: boolean;
  extendedBlockType?: ExtendedEventBlock;
  basisKey: SportlineFragmentBasisKey;
}

interface SportlineLeagueBlockEmits {
  (e: 'click-event'): void;
}

const props = defineProps<SportlineLeagueBlockProps>();
const emit = defineEmits<SportlineLeagueBlockEmits>();

const content = ref<Optional<HTMLDivElement>>();

const leagueElement = toRef(props, 'leagueElement');
const isDisplayMainEventsDisabled = toRef(props, 'isDisplayMainEventsDisabled');
const isColumnNamesDisplayDisabled = toRef(props, 'isColumnNamesDisplayDisabled');
const isDisplayOutrightEventsEnabled = toRef(props, 'isDisplayOutrightEventsEnabled');
const basisKey = toRef(props, 'basisKey');

const leagueEventsCount = computed(() => {
  if (isDisplayMainEventsDisabled.value) {
    return leagueElement.value.counters.outright;
  }

  if (!isDisplayOutrightEventsEnabled.value) {
    return leagueElement.value.counters.live + leagueElement.value.counters.prematch;
  }

  return leagueElement.value.counters.total;
});
const isHeadlineColumnNamesDisplayDisabled = computed<boolean>(() => (
  isDisplayMainEventsDisabled.value
  || isColumnNamesDisplayDisabled.value
  || leagueElement.value.sportEvents.length === 0
));

const {
  isExpanded,
  isExpandable,
  expandAnimationActive,
  expandAnimationDuration,
  toggleExpandState,
} = useExpandableSportlineBlock({
  content,
  expandKey: toRef(props, 'expandKey', null),
  isDefaultExpanded: toRef(props, 'isDefaultExpanded'),
});
</script>

<template>
  <div v-auto-id="'SportlineLeagueBlock'" :class="$style['league-element-inner__holder']">
    <LeagueHeadline
      v-if="!isHideLeagueInfo"
      :key="leagueElement.key"
      :class="$style['league-headline']"
      :sport="sport"
      :region="region"
      :league-element="leagueElement"
      :is-show-add-league-to-favorite="isShowAddLeagueToFavorite"
      :is-column-names-display-disabled="isHeadlineColumnNamesDisplayDisabled"
      :expand-key="expandKey"
      :is-expanded="expandAnimationActive ? !isExpanded : isExpanded"
      :is-filter-active="isFilterActive"
      :count-events="leagueEventsCount"
      :basis-key="basisKey"
      @toggle-expand-state="toggleExpandState"
    />

    <div
      ref="content"
      :class="{
        [$style['expandable-element-content']]: true,
        [$style['expandable-element-content--hidden']]: isExpandable && !isExpanded,
      }"
      :style="{ 'transition-duration': `${expandAnimationDuration}ms` }"
    >
      <div :class="$style['sportline-events-list']">
        <template v-if="!isDisplayMainEventsDisabled">
          <div
            v-if="!isHideLeagueInfo"
            v-data-test="{ el: 'league-block-headline-holder' }"
          />

          <SportlineEventBlock
            v-for="sportEventElement in leagueElement.sportEvents"
            :key="sportEventElement.sportEvent.id"
            :sport="sport"
            :sport-event-element="sportEventElement"
            :upcoming-label-type="upcomingLabelType"
            :can-add-to-favorite="canAddToFavorite"
            :is-extended-event-view-disabled="isExtendedEventViewDisabled"
            :active-event-id="activeEventId"
            :extended-block-type="extendedBlockType"
            :basis-key="basisKey"
            @click="emit('click-event')"
          />
        </template>
        <div
          v-else-if="!isHideLeagueInfo"
          v-data-test="{ el: 'league-block-outright-headline-holder' }"
        />

        <template v-if="isDisplayOutrightEventsEnabled && leagueElement.outrightEvents.length > 0">
          <div
            v-if="!isDisplayOutrightHeadlineDisabled"
            :class="$style['league-element-inner__outrights-title']"
          >
            {{ $t('WEB2_LEAGUE_OUTRIGHT_WINNERS') }}
          </div>

          <SportlineEventBlock
            v-for="outrightEvent in leagueElement.outrightEvents"
            :key="outrightEvent.sportEvent.id"
            :sport="sport"
            :sport-event-element="outrightEvent"
            :upcoming-label-type="upcomingLabelType"
            :can-add-to-favorite="canAddOutrightsToFavorite"
            :active-event-id="activeEventId"
            :extended-block-type="extendedBlockType"
            :basis-key="basisKey"
            @click="emit('click-event')"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/styles/expandable-element';

@include for-layout using ($isDesktop) {
  .sportline-events-list {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .league-headline {
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }
  }

  .league-element-inner {
    &__holder {
      padding: 0;
      margin: 0 0 8px;
      overflow: hidden;
      background-color: var(--Layer0);
      border-radius: 5px;

      &:last-child {
        border-radius: if($isDesktop, 0 0 var(--BorderRadius) var(--BorderRadius), var(--BorderRadius));
      }
    }

    &__outrights-title {
      @include medium\14\20\025;

      display: flex;
      align-items: center;
      height: 44px;
      padding: 0 12px;
      background-color: var(--Layer1);

      &:not(:first-child) {
        margin-top: 8px;
        border-radius: var(--BorderRadius) var(--BorderRadius) 0 0;
      }
    }
  }
}
</style>
